export const customerData = [
    {
        question: 'আমি কিভাবে রেজিস্ট্রেশন করতে পারি ?',
        answer: 'রেজিস্ট্রেশন করুন এই লিঙ্কে https://lpgshopbd.com/register'
    },
    {
        question: 'আমি কি এ্যাপ সম্পর্কে জানতে পারি?',
        answer: 'আমাদের এলপিজি শপ এর সুবিধা হচ্ছে , একজন গ্রাহক / ক্রেতা গ্যাস সিলিন্ডার অর্ডার করার সাথে সাথে তার নিকটস্থ  এজেন্ট / বিক্রেতার নিকট অর্ডার তালিকা পৌঁছে যাবে। অর্ডার নিশ্চিত করে এজেন্ট/ বিক্রেতা পণ্যটি দ্রুততম সময়ের মধ্যে হোম ডেলিভারি সম্পন্ন করবে। এলপিজি শপ এ্যাপ ব্যবহার করে এজেন্ট/ বিক্রেতা অনলাইনে বেশি বেশি অর্ডার পাবে।আমাদের এলপিজি শপ এজেন্ট/বিক্রেতা এবং কাস্টমার এ্যাপটি খুব শীঘ্রই গুগল প্লে স্টোর,এ্যাপ স্টোর থেকে ডাউনলোড করতে পারবেন।'
    },
    {
        question: 'আমরা/ব্যাবসায়িরা বিক্রেতা হয়ে কাজ করলে আমাদের লাভ/কমিশন কি?',
        answer: 'এলপিজি শপ বিডি এ্যাপ ব্যবহারের মাধ্যমে পূর্বের তুলনায় বেশী বেশী অর্ডার পাবেন।প্রথম ৬ মাস আপনি  ফ্রিতে আমাদের এলপিজি শপ এ্যাপ ব্যবহার করতে পারবেন কোন প্রকার কমিশন ছাড়াই ,সম্পূর্ণ লাভ  আপনার নিজের থাকবে ।'
    },
    {
        question: 'এলপিজি শপ বিডি সেলার এ্যাপ  এর ব্যবহার পদ্ধতি সম্পর্কে জানতে পারি ?',
        answer: 'এলপিজি শপ বিডি  সেলার এ্যাপে আপনার নিজস্ব একটি অ্যাকাউন্ট খুলুন।এরপর লগইন করুন। তাহলে আপনি আপনার কাস্টমারের অর্ডার তালিকা দেখতে পাবেন। কাস্টমারের অর্ডারটি তাৎক্ষণিকভাবে আপনার এ্যাপে পৌঁছে যাবে। । এ্যাপ- এর মাধ্যমে এজেন্ট তার অর্ডার তালিকা থেকে অর্ডার গ্রহণ বা বাতিল করতে পারবেন। আমাদের এলপিজি শপ বিডি সেলার এবং কাস্টমার এ্যাপটি খুব শীঘ্রই গুগল প্লে স্টোর,এ্যাপ  স্টোর থেকে ডাউনলোড করতে পারবেন।'
    },
    {
        question: 'এলপিজি শপ  এ্যাপের মাধ্যমে হোম ডেলিভারি সুবিধা পাওয়া যাবে?',
        answer: 'হ্যা,অবশ্যই। এলপিজি শপ এ্যাপ ব্যবহারে মাধ্যমে পাচ্ছেন খুব দ্রুততম সময়ের মাধ্যমে হোম ডেলিভারি সুবিধা।'
    },
 
]


export const sellerData = [
    {
        question: 'আমি কিভাবে রেজিস্ট্রেশন করতে পারি ?',
        answer: 'রেজিস্ট্রেশন করুন এই লিঙ্কে https://lpgshopbd.com/register'
    },
    {
        question: 'আমি কি এ্যাপ সম্পর্কে জানতে পারি?',
        answer: 'আমাদের এলপিজি শপ এর সুবিধা হচ্ছে , একজন গ্রাহক / ক্রেতা গ্যাস সিলিন্ডার অর্ডার করার সাথে সাথে তার নিকটস্থ  এজেন্ট / বিক্রেতার নিকট অর্ডার তালিকা পৌঁছে যাবে। অর্ডার নিশ্চিত করে এজেন্ট/ বিক্রেতা পণ্যটি দ্রুততম সময়ের মধ্যে হোম ডেলিভারি সম্পন্ন করবে। এলপিজি শপ এ্যাপ ব্যবহার করে এজেন্ট/ বিক্রেতা অনলাইনে বেশি বেশি অর্ডার পাবে।আমাদের এলপিজি শপ এজেন্ট/বিক্রেতা এবং কাস্টমার এ্যাপটি খুব শীঘ্রই গুগল প্লে স্টোর,এ্যাপ স্টোর থেকে ডাউনলোড করতে পারবেন।'
    },
    {
        question: 'আমরা/ব্যাবসায়িরা বিক্রেতা হয়ে কাজ করলে আমাদের লাভ/কমিশন কি?',
        answer: 'এলপিজি শপ বিডি এ্যাপ ব্যবহারের মাধ্যমে পূর্বের তুলনায় বেশী বেশী অর্ডার পাবেন।প্রথম ৬ মাস আপনি  ফ্রিতে আমাদের এলপিজি শপ এ্যাপ ব্যবহার করতে পারবেন কোন প্রকার কমিশন ছাড়াই ,সম্পূর্ণ লাভ  আপনার নিজের থাকবে ।'
    },
    {
        question: 'এলপিজি শপ বিডি সেলার এ্যাপ  এর ব্যবহার পদ্ধতি সম্পর্কে জানতে পারি ?',
        answer: 'এলপিজি শপ বিডি  সেলার এ্যাপে আপনার নিজস্ব একটি অ্যাকাউন্ট খুলুন।এরপর লগইন করুন। তাহলে আপনি আপনার কাস্টমারের অর্ডার তালিকা দেখতে পাবেন। কাস্টমারের অর্ডারটি তাৎক্ষণিকভাবে আপনার এ্যাপে পৌঁছে যাবে। । এ্যাপ- এর মাধ্যমে এজেন্ট তার অর্ডার তালিকা থেকে অর্ডার গ্রহণ বা বাতিল করতে পারবেন। আমাদের এলপিজি শপ বিডি সেলার এবং কাস্টমার এ্যাপটি খুব শীঘ্রই গুগল প্লে স্টোর,এ্যাপ  স্টোর থেকে ডাউনলোড করতে পারবেন।'
    },
    {
        question: 'এলপিজি শপ  এ্যাপের মাধ্যমে হোম ডেলিভারি সুবিধা পাওয়া যাবে?',
        answer: 'হ্যা,অবশ্যই। এলপিজি শপ এ্যাপ ব্যবহারে মাধ্যমে পাচ্ছেন খুব দ্রুততম সময়ের মাধ্যমে হোম ডেলিভারি সুবিধা।'
    },
 
]
import React from 'react'
import './ScreenShots.css'
import image1 from '../../Image/app-screenshots/7.png'
import image2 from '../../Image/app-screenshots/8.png'
import image3 from '../../Image/app-screenshots/9.png'
import image4 from '../../Image/app-screenshots/10.png'
import image5 from '../../Image/app-screenshots/11.png'
import image6 from '../../Image/app-screenshots/12.png'
import image7 from '../../Image/app-screenshots/13.png'
import image8 from '../../Image/app-screenshots/14.png'
import image9 from '../../Image/app-screenshots/15.png'
import image10 from '../../Image/app-screenshots/16.png'
import AppleStore from '../../Image/apple.png'
import PlayStore from '../../Image/play.png'
import CoomingSoon from '../../Image/coming.png'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import { Spinner } from 'react-bootstrap'
import { useEffect, useState } from 'react'


export const ScreenShots = () => {
    const [Looding, setLooding] = useState(false)
    useEffect(() => {
        setLooding(true);
        setTimeout(() => {
            setLooding(false)
        }, 500)
    }, [])

    const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
            13000: {
                items: 5,
            },
        },
    }

    return (        
        <>  
            {Looding ? (<div className="spinners"><Spinner animation="border" /></div>) : (
                <>
              <section className="app-download section-padding">
                  <div className="container">
                      <div className="row justify-content-center">
                          <div className="col-lg-8">
                              <div className="section-title">
                                  <h2>ডাউনলোড এ্যাপ</h2>
                              </div>
  
                          </div>
                      </div>
                      <div className="row justify-content-center">
                          <div className="col-lg-4 col-md-6">
                              <div className="app-download-item">
                                  <i className="fab fa-google-play"></i>
                                  <h3>গুগল প্লে স্টোর</h3>
                                  <Link to="/" ><img src={PlayStore} alt="" style={{width:"80%",height:"80%"}}/></Link>
                              </div>                       
                          </div>
                          <div className="col-lg-4 col-md-6">
                              <div className="app-download-item">
                                      <i className="fab fa-apple"></i>
                                      <h3>অ্যাপ স্টোর</h3>
                                      <Link to="/"><img src={AppleStore} alt="" style={{width:"80%",height:"80%"}}/></Link>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-6">
                                  <div className="app-download-item">
                                      <i className="fa fa-download"></i>
                                      <h3>বিক্রেতার এ্যাপ</h3>
                                      <Link to="/"><img src={CoomingSoon} alt="" style={{width:"80%",height:"80%"}}/></Link>
                                  </div>
                              </div>
                      </div>
                  </div>
              </section>
              <section className="how-it-works section-padding">
                 <div className="container">
                     <div className="row justify-content-center">
                         <div className="col-lg-8">
                              <div className="section-title">
                                  <h2>কিভাবে  <span>কাজ করে</span></h2>
                              </div>
                         </div>
                     </div>
                     <div className="row">
                         <div className="col-lg-3 col-md-6">
                             <div className="how-it-works-item line-right">
                                 <div className="step">1</div>
                                 <h3>ডাউনলোড</h3>
                                 <p>গুগল প্লে স্টোর অথবা অ্যাপ স্টোর থেকে এলপিজি শপ এ্যাপটি ডাউনলোড করুন ।</p>
                             </div>
                         </div>
                         <div className="col-lg-3 col-md-8">
                             <div className="how-it-works-item line-right">
                                 <div className="step">2</div>
                                 <h3>লোকেশন নির্বাচন</h3>
                                 <p>আপনার লোকেশন নির্বাচন করুন । <br/> <br/></p>
                             </div>
                         </div>
                         <div className="col-lg-3 col-md-6">
                             <div className="how-it-works-item line-right">
                                 <div className="step">3</div>
                                 <h3>অর্ডার</h3>
                                 <p>আপনার চাহিদা/প্রয়োজন অনুযায়ী এলপিজি সিলিন্ডার নির্বাচন করুন ।</p>
                             </div>
                         </div>
                         <div className="col-lg-3 col-md-6">
                             <div className="how-it-works-item">
                                 <div className="step">4</div>
                                 <h3>ডেলিভারি ঠিকানা</h3>
                                 <p>যে ঠিকানায় অর্ডারটি পৌঁছাতে হবে সে ঠিকানা লিখুন ।</p>
                             </div>
                         </div>
                     </div>
                 </div>
              </section>
              {/* <section className="app-screen section-padding">
                <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-8">
                          <div className="section-title">
                              <h2 className="animate__animated animate__bounce animate__fadeInDown">app <span>screenshots</span> For Customers/Users</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <OwlCarousel 
                          className='screenshots-carosel' 
                          loop={true}
                          dots={true}
                          autoplay
                          margin={10}
                          items={4}
                          responsive={state.responsive} >
                          <div className="item">
                              <img src={image1} alt="" />
                          </div>
                          <div className="item">
                              <img src={image2} alt="" />
                          </div>
                          <div className="item">
                              <img src={image3} alt="" />
                          </div>
                          <div className="item">
                              <img src={image4} alt="" />
                          </div>
                          <div className="item">
                              <img src={image5} alt="" />
                          </div>
                      </OwlCarousel>                    
                  </div>
                </div>
              </section> 
              <section className="app-screen section-padding">
                <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-8">
                          <div className="section-title">
                              <h2 className="animate__animated animate__bounce animate__fadeInDown">app <span>screenshots</span> For Merchant/Seller</h2>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <OwlCarousel 
                          className='screenshots-carosel' 
                          loop={true}
                          dots={true}
                          autoplay
                          margin={10}
                          items={4}
                          responsive={state.responsive} >
                          <div className="item">
                              <img src={image6} alt="" />
                          </div>
                          <div className="item">
                              <img src={image7} alt="" />
                          </div>
                          <div className="item">
                              <img src={image8} alt="" />
                          </div>
                          <div className="item">
                              <img src={image9} alt="" />
                          </div>
                          <div className="item">
                              <img src={image10} alt="" />
                          </div>
                      </OwlCarousel>                    
                  </div>
                </div>
              </section>  */}
              <Footer />
                
                </>
            )}
                  
          
       
        </>
    )
}

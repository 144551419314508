import React, { useEffect, useState } from 'react'
import {useFormik} from 'formik'
import * as Yup from "yup";
import {useHistory} from 'react-router-dom'
import firebase from '../../firebase'
import { Spinner } from 'react-bootstrap'
import Footer from '../../Components/Footer/Footer'
import './Contact.css'

const Contact = () => {
    const [Looding, setLooding] = useState(false)
        const history = useHistory()
    useEffect(() => {
        setLooding(true);
        setTimeout(() => {
            setLooding(false)
        }, 500)
    }, [])

    const handleSubmit = async (values) => {
        console.log("....values",values)
        setLooding(true);
        await firebase.firestore().collection("lpg-shop-contact").add({
          createdAt: new Date(),
          name: values.name,
          email: values.email,
          mobile: values.mobile,
          subject: values.subject,
          message: values.message

        });
        setTimeout(() => {
            setLooding(false);
                history.push("/")
        }, 800);
      };

    const formik = useFormik({
        initialValues: {
          name: "",
          email: "",
          mobile: "",
          subject:"",
          message:""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("আপনার নাম প্রয়োজন"),
            email: Yup.string().email("ইমেইলটি সঠিক নয়").required("আপনার ইমেইল প্রয়োজন"),
            mobile: Yup.string().required("মোবাইল /ফোন নম্বর দিন"),
            subject: Yup.string().required("বিষয় উল্লেখ করুন"),
            message: Yup.string().required("বিস্তারিত লিখুন"),
        }),
        onSubmit: (values) => {
         handleSubmit(values)
        }
      });

    return (
        <>
        {Looding ? (<div className="spinners"><Spinner animation="border" /></div>) : (
            <>
           <section className="contact section-padding">
               <div className="container">
                   <div className="row justify-content-center">
                       <div className="col-lg-8">
                           <div className="section-title">
                               <h2 className="animate__animated animate__bounce animate__fadeInDown">আমাদের   <span>সাথে</span> থাকুন</h2>
                           </div>
                       </div>
                   </div>
                   <div className="row jutify-content-center">
                       <div className="col-lg-4 col-md-5">
                            <div className="contact-info">
                                <h3 className="animate__animated animate__bounce animate__delay-1s animate__fadeInLeft">তথ্য ও সহায়তার জন্য</h3>
                                <div className="contact-info-item animate__animated animate__bounce animate__delay-1s animate__fadeInLeft">
                                    <i className="fas fa-location-arrow"></i>
                                    <h4>কোম্পানি লোকেশন</h4>
                                    <p>মালিবাগ,ঢাকা ১২১৯</p>
                                    <p>বিস্তারিত জানতে ভিজিট করুন<a href="https://www.bitsofts.io"> <span>www.bitsofts.io</span></a></p>
                                </div>
                                <div className="contact-info-item animate__animated animate__bounce animate__delay-2s animate__fadeInLeft">
                                    <i className="fas fa-envelope"></i>
                                    <h4>আমাদের কাছে লিখুন</h4>
                                    <p>support@bitsofts.io</p>
                                </div>
                                <div className="contact-info-item animate__animated animate__bounce animate__delay-3s animate__fadeInLeft">
                                    <i className="fas fa-phone"></i>
                                    <h4>আমাদের কাছে ফোন করুন</h4>
                                    <p>+880 1711 385722</p>
                                </div>
                            </div>
                       </div>
                       <div className="col-lg-8 col-lg-7">
                            <div className="contact-form">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input 
                                                type="text"
                                                name="name" 
                                                placeholder="নাম" 
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name} 
                                                />
                                                  {formik.touched.name && formik.errors.name ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.name}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input 
                                                type="email"
                                                name="email" 
                                                placeholder="ইমেইল" 
                                                className="form-control" 
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email} 
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.email}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input 
                                                type="text" 
                                                placeholder="ফোন / মোবাইল নাম্বার" 
                                                className="form-control"
                                                name="mobile"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile}  
                                                />
                                                {formik.touched.mobile && formik.errors.mobile ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.mobile}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input 
                                                type="text" 
                                                placeholder="বিষয়" 
                                                className="form-control"
                                                name="subject"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.subject}  
                                                />
                                                {formik.touched.subject && formik.errors.subject ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.subject}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <textarea 
                                                placeholder="বিস্তারিত ম্যাসেজ লিখুন...." 
                                                className="form-control"
                                                name="message"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.message} 
                                                />
                                                {formik.touched.message && formik.errors.message ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.message}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                           <button type="submit" className="btn btn-1">ম্যাসেজ সেন্ড করুন</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                       </div>
                   </div>
               </div>
           </section>
           <Footer />
           </>
        )}
        </>
    )
}

export default Contact

import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Feature.css'
import { Funfacts } from '../FunFacts/Funfacts';
import Footer from '../../Components/Footer/Footer';
import { Spinner } from 'react-bootstrap';

export const Feature = () => {
    const [Looding, setLooding] = useState(false)
    useEffect(() => {
        setLooding(true);
        setTimeout(() => {
            setLooding(false)
        }, 500)
    }, [])
    const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }

    return (
        <>
        {Looding ? (<div className="spinners"><Spinner animation="border" /></div>) : (

            <>
                <section className="features section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title">
                                <h2 className="animate__animated animate__bounce animate__delay-1s animate__fadeInLeft">এলপিজি শপ বিডি <span>এ্যাপের</span> বৈশিষ্ট্য</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <OwlCarousel className='features-theme owl-theme'                        
                        loop={true}
                        dots={true}
                        autoplay
                        margin={10}
                        items={4}
                        responsive={state.responsive} >
                            <div class='item'>
                                <div className="icon">
                                    <i className="fas fa-users"> </i>
                                </div>
                                <h3>এলপিজি কাস্টমার অ্যাপ</h3>
                                <p>ব্যবহারকারীর অর্ডারকৃত গ্যাস সিলিন্ডার তার নিকটস্থ এজেন্ট/বিক্রেতার থেকে দ্রুততম সময়ের মধ্যে হোম ডেলিভারি পেয়ে যাবেন।</p>
                            </div>
                            <div class="item">
                                <div className="icon">
                                <i class="fas fa-business-time"></i>
                                </div>
                                <h3>এলপিজি মার্চেন্ট অ্যাপ</h3>
                                <p>বিক্রয় বৃদ্ধি করুন আমাদের এলপিজি সেলার অ্যাপ ব্যবহারের মাধ্যমে। <br/> <br/></p>
                            </div>
                            <div class="item">
                                <div className="icon">
                                    <i className="fab fa-facebook"> </i>
                                </div>
                                <h3>ফেইসবুকের মাধ্যমে অর্ডার</h3>
                                <p>আমাদের ফেইসবুক পেজ এবং ফেইসবুক গ্রুপ এর মাধ্যমে অর্ডার করতে পারবেন । <br/> <br/></p>
                            </div>
                            <div class="item">
                                <div className="icon">
                                    <i className="fas fa-phone"> </i>
                                </div>
                                <h3>ফোন কলের মাধ্যমে অর্ডার</h3>
                                <p>ফোনে অর্ডার করতে কল করুন এই নাম্বারে +880 1711 385722 <br/> <br/></p>
                            </div>
                            <div class="item">
                                <div className="icon">
                                <i class="fas fa-american-sign-language-interpreting"></i>
                                </div>
                                <h3>আপকামিং ফিচার</h3>
                                <p>আমাদের এলপিজি শপ বিডি এ্যাপের নুতন কিছু ফিচার যুক্ত হচ্ছে যেমন, গ্যাস সিলিন্ডার বা চুলা ক্রয় করতে পারবেন এবং মেরামত করার সুবিধা পাবেন</p>
                            </div>                               
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            <Funfacts />
            {/* <Footer /> */}
            
            </>

        )}
            
        </>
    )
}




                         
                            
                            







import React, { useEffect, useState } from 'react'
import './Home.css'
import { Link } from 'react-router-dom'
import image from '../../Image/app-screenshots/6.png'
import image2 from '../../Image/app-screenshots/7.png'
import { Spinner } from 'react-bootstrap'
import {Feature} from '../Feature/Feature'
import {ScreenShots} from '../AppScreenShots/ScreenShots'
import {Helmet} from "react-helmet";
// import Logo from '../../Image/logo.png'
export const Home = () => {
    const [Looding, setLooding] = useState(false)
    useEffect(() => {
        setLooding(true);
        setTimeout(() => {
            setLooding(false)
        }, 500)
    }, [])
    const [PlayerOpen, setPlayerOpen] = useState(false)
    const playNow = () => {
        setPlayerOpen(!PlayerOpen)
    }
    return (
        <>
            {Looding ? (<div className="spinners"><Spinner animation="border" /></div>) : (
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>LPG Shop BD - Your trusted partner</title>
                        <meta name="description" content="LPG Shop Bd - help you to order LPG Cylinder and home delivery."></meta>
                    </Helmet>
                    <section className="home d-flex align-items-center">
                        <div className="effect-wrap">
                            <i className="fas fa-plus effect effect-1"></i>
                            <i className="fas fa-plus effect effect-2"></i>
                            <i class="fas fa-alarm-clock effect effect-2"></i>

                            <i className="fas fa-circle-notch effect effect-3"></i>
                            <i class="fas fa-american-sign-language-interpreting effect effect-1"></i>
                        </div>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-7 ">
                                    <div className="home-text">
                                        {/* <h1>LPG SHOP BD</h1> */}
                                        <p className="animate__animated animate__bounce animate__delay-1s animate__fadeInLeft">বাংলাদেশে এলপিজি শপ বিডি -ই প্রথম আপনাকে দিচ্ছে ঘরে বসে গ্যাস সিলিন্ডার অর্ডারের সুবিধা সারা দেশ জুড়ে।বেছে নিন সুবিধামত সাইজে আপনার পছন্দের ব্র্যান্ডের গ্যাস সিলিন্ডার! আমরা দিচ্ছি আপনাকে দেশ সেরা সব  গ্যাস সিলিন্ডারের ব্র্যান্ড একটি অ্যাপে!</p>
                                        <div className="home-btn">
                                            <Link className="btn btn-1 animate__animated animate__bounce animate__delay-2s animate__fadeInLeft" to="/register">মার্চেন্ট রেজিষ্ট্রেশন</Link>
                                            <button type="button" className="btn btn-1 video-play-btn" onClick={playNow}>
                                                <i class="fas fa-play"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 text-center">
                                    <div className="home-img animate__animated animate__bounce animate__fadeInDown">
                                        <div className="circles">
                                        </div>
                                        <img src={image} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className={PlayerOpen ? 'video-popup open' : 'video-popup'}>
                        <div className="video-popup-inner">
                            <i className="fas fa-times video-player-close" onClick={playNow}></i>
                            <div className="iframe-box">
                                <iframe id="player-1" src="https://www.youtube.com/embed/iM9qQeRlnpM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                 
                    <Feature/>
                    <ScreenShots/>
                </>
              

            )}

        </>
    )
}


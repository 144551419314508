import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
    return (
        <>
            <footer className="footer">
               <div className="container ">
                   <div className="row justify-content-center">
                       <div className="col-lg-3 col-md-6">
                           <div className="footer-col">
                               <h3>আমাদের সম্পর্কে বিস্তারিত</h3>
                               <p> এলপিজি শপ বিডি একটি মাল্টিভেন্ডর মার্কেট প্লেস।এখানে এলপিজি ব্যবসায়ীরা তাদের পণ্য প্রর্দশন ও বিক্রয় করতে পারবে। এলপিজি শপ বিডি এ্যাপের মাধ্যমে গ্যাস সিলিন্ডার, চুলা এ যাবতীয় জিনিস ক্রয় করা যাবে এবং মেরামত সুবিধা পাওয়া যাবে ।</p>
                           </div>
                       </div>
                       <div className="col-lg-3 col-md-6">
                           <div className="footer-col">
                               <h3>কোম্পানি</h3>
                                <div className="footer-link">
                                    <div className="link-item">
                                    <Link>প্রাইভেসি পলিসি</Link></div>
                                    <div className="link-item"><Link>টার্ম এন্ড কন্ডিশন</Link></div>
                                    {/* <div className="link-item"><Link>Lates Blogs</Link></div> */}
                                    <div className="link-item"><Link>এ্যাপ সার্ভিস</Link></div>
                                </div>
                           </div>
                       </div>
                       <div className="col-lg-3 col-md-6">
                           <div className="footer-col">
                               <h3>কুইক লিংকস</h3>
                               <div className="footer-link">
                                <div className="link-item"><Link to="/">হোম</Link></div>
                                {/* <div className="link-item"><Link to="/feautures">Features</Link></div>
                                <div className="link-item"><Link to="/screenshots">Screenshots</Link></div>
                                <div className="link-item"><Link to="/testmonials">Testimonials</Link></div> */}
                                <div className="link-item"><Link to="/faqs">প্রশ্ন জিজ্ঞেসা</Link></div>
                                <div className="link-item"><Link to="/contact">যোগাযোগ</Link></div>
                               </div>                               
                           </div>
                       </div>
                       <div className="col-lg-3 col-md-6">
                           <div className="footer-col">
                               <h3>সামাজিক পেজ</h3>
                               <div className="footer-link">
                                <div className="link-item"><a href='https://www.facebook.com/lpgshopbd'> <i class="fab fa-facebook-square"></i> ফেইসবুক</a></div>
                                {/* <div className="link-item"><a href="#"><i class="fab fa-twitter"></i> টুইটার</a></div> */}
                                {/* <div className="link-item"><Link>Instagram</Link></div> */}
                                {/* <div className="link-item"><a href="#"><i class="fab fa-linkedin-in"></i> লিংকড-ইন</a></div> */}
                               </div>
                           </div>
                       </div>
                   </div>                   
               </div>
               <div className="row justify-content-center">
                       <div className="col-lg-12">
                           <div className="copyright-text">
                            &copy; 2021 LPG SHOP BD. All Right Reserved
                           </div>
                       </div>
                   </div>
                
            </footer>   
        </>
    )
}

export default Footer

import React, { useEffect, useState } from 'react'
import './Faqs.css'
import {Spinner } from 'react-bootstrap'

import Footer from '../../Components/Footer/Footer'
import According from './According'


export const FaqsSeller = () => {
  const [Looding, setLooding] = useState(false)
    useEffect(() => {
        setLooding(true);
        setTimeout(() => {
            setLooding(false)
        }, 500)
    }, [])

  return (
    <>
    {Looding ? (<div className="spinners"><Spinner animation="border" /></div>) : (
      <>
      <section className="faq section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title">
                <h2 className="animate__animated animate__bounce animate__fadeInDown">সচরাচর  <span>বিক্রেতার প্রশ্নের</span> উত্তর</h2>
              </div>
            </div>
          </div>         
          <div className="row justify-content-center">
            <div className="col-lg-10 mt-5">
                <According />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      </>
    )}
      
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import './Faunfacts.css'
import image from '../../Image/app-screenshots/1.png'
import image2 from '../../Image/app-screenshots/6.png'
import image3 from '../../Image/app-screenshots/3.png'

export const Funfacts = () => {
    return (
        <>
            <section className="fun-facts section-padding">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6 col-md-5 ">
                            <div className="fan-facts-img">
                                <img src={image2} alt="" />
                                <div className="register-button">
                                <Link className="btn btn-1" to="/register">মার্চেন্ট রেজিষ্ট্রেশন</Link>
                            </div>
                            </div>
                           
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="section-title">
                                <h2 className="animate__animated animate__bounce animate__fadeInDown">বিক্রেতার <span>তথ্যবলি</span></h2>
                            </div>
                            <div className="fan-facts-text">
                                <p>এজেন্ট / বিক্রেতারা আমাদের এলপিজি শপ বিডি এ্যাপ  এর মাধ্যমে তাদের ব্যবসায় পরিচালনা করতে পারবে এবং ব্যবসায় পরিধি বৃদ্ধি করতে পারবে। এজেন্ট / বিক্রেতা গ্যাস সিলিন্ডার বিক্রয়ের পরিমাণ বৃদ্ধি করতে পারবে ।</p>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="fan-facts-item style-1">
                                            <h3>200</h3>
                                            <span>দৈনিক অর্ডার</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="fan-facts-item style-2">
                                            <h3>1400</h3>
                                            <span>সাপ্তাহিক অর্ডার</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="fan-facts-item style-3">
                                            <h3>4000</h3>
                                            <span>মাসিক অর্ডার</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="fan-facts-item style-4">
                                            <h3>12000</h3>
                                            <span>বাৎসরিক অর্ডার</span>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

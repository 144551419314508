import  firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage'
var firebaseConfig = {
    apiKey: "AIzaSyAs3Wkvcfy0Jwaj-M5Atk860sZHID_Q_KI",
    authDomain: "lpg-shop.firebaseapp.com",
    projectId: "lpg-shop",
    storageBucket: "lpg-shop.appspot.com",
    messagingSenderId: "1073396556872",
    appId: "1:1073396556872:web:2f508d5ca794c7ce08a47d",
    measurementId: "G-WJYTZWZSB4"
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig)
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else {
  firebase.app(); // if already initialized, use that one
}

export default firebase;
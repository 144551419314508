import React, { useEffect, useState } from 'react'
import {useFormik} from 'formik'
import * as Yup from "yup";
import firebase from '../../firebase'
import { Spinner } from 'react-bootstrap'
import Footer from '../../Components/Footer/Footer'
import {bd_division} from './Data'
import './Register.css'
import {useToasts } from 'react-toast-notifications';
const Register = () => {
        const { addToast } = useToasts();
        const [Looding, setLooding] = useState(false)
         const [selectedDivision,setSelectedDivision]= useState("")
         const [selectedDistrict,setSelectedDistrict]= useState("")
         const [show, setShow] = useState();
         const [showError, setShowError] = useState();
    useEffect(() => {
        setLooding(true);
        setTimeout(() => {
            setLooding(false)
        }, 500)
    }, [])

    const handleChangesSelectedDivision =(e)=>{
        setSelectedDivision(e.target.value)
    }

    const handleChangesSelectedDistrict = (e) => {
        setSelectedDistrict(e.target.value)
    }


    const barisal = bd_division.filter((item, index)=> {
        if(item.name.toLocaleLowerCase()=='barisal'){
          return item
        }
      })[0];
 
      const chittagong = bd_division.filter((item, index)=> {
        if(item.name.toLocaleLowerCase()=='chittagong'){
          return item
        }
      })[0];
    
      const dhaka = bd_division.filter((item, index)=> {
        if(item.name.toLocaleLowerCase()=='dhaka'){
          return item
        }
      })[0];
      const khulna = bd_division.filter((item, index)=> {
        if(item.name.toLocaleLowerCase()=='khulna'){
          return item
        }
      })[0];
      const mymensingh = bd_division.filter((item, index)=> {
        if(item.name.toLocaleLowerCase()=='mymensingh'){
          return item
        }
      })[0];
      const rajshahi = bd_division.filter((item, index)=> {
        if(item.name.toLocaleLowerCase()=='rajshahi'){
          return item
        }
      })[0];
      const rangpur = bd_division.filter((item, index)=> {
        if(item.name.toLocaleLowerCase()=='rangpur'){
          return item
        }
      })[0];
      const sylhet = bd_division.filter((item, index)=> {
        if(item.name.toLocaleLowerCase()=='sylhet'){
          return item
        }
      })[0];


        let type = null;
        let options = null;

      if(selectedDivision === barisal.bn_name){
            type = barisal.districts
        } else if (selectedDivision === chittagong.bn_name){
                type = chittagong.districts
        } else if (selectedDivision === dhaka.bn_name){
                type = dhaka.districts
        }else if (selectedDivision === khulna.bn_name){
                type = khulna.districts
        } else if (selectedDivision === rajshahi.bn_name){
                type = rajshahi.districts
        }else if (selectedDivision === rangpur.bn_name){
                type = rangpur.districts
        } else if (selectedDivision === sylhet.bn_name){
                type = sylhet.districts
        } else if (selectedDivision === mymensingh.bn_name){
                type = mymensingh.districts
        }
 

        if (type) {
            options = type.map((el,i) => <option key={el}>{el.bn_name}</option>);
        }


        let typeUpazila= null;
        let optionsUpazila = null;

                    // barisalDistrict

        const barisalDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="barisal"){
                return el
            }
        })[0];
        const bargunaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="barguna"){
                return el
            }
        })[0];

        const pirojpurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="pirojpur"){
                return el
            }
        })[0];
        const bholaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="bhola"){
                return el
            }
        })[0];
        const jhalokatiDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="jhalokati"){
                return el
            }
        })[0];
        const patuakhaliDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="patuakhali"){
                return el
            }
        })[0];

        // Dhaka district

        const dhakaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="dhaka"){
                return el
            }
        })[0];
        const faridpurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="faridpur"){
                return el
            }
        })[0];

        const gazipurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="gazipur"){
                return el
            }
        })[0];
        const gopalganjDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="gopalganj"){
                return el
            }
        })[0];
        const kishoreganjDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="kishoreganj"){
                return el
            }
        })[0];
        const madaripurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="madaripur"){
                return el
            }
        })[0];
        const manikganjDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="manikganj"){
                return el
            }
        })[0];
        const munshiganjDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="munshiganj"){
                return el
            }
        })[0];
        const narayanganjDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="narayanganj"){
                return el
            }
        })[0];
        const rajbariDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="rajbari"){
                return el
            }
        })[0];
        const narsingdiDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="narsingdi"){
                return el
            }
        })[0];
        const shariatpurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="shariatpur"){
                return el
            }
        })[0];
        const tangailDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="tangail"){
                return el
            }
        })[0];

        // Chittagong district

        const chittagongDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="chittagong"){
                return el
            }
        })[0];
        const bandarbanDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="bandarban"){
                return el
            }
        })[0];

        const brahmanbariaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="brahmanbaria"){
                return el
            }
        })[0];
        const chandpurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="chandpur"){
                return el
            }
        })[0];
        const comillaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="comilla"){
                return el
            }
        })[0];
        const coxbazarDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="cox's bazar"){
                return el
            }
        })[0];
        const feniDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="feni"){
                return el
            }
        })[0];
        const khagrachariDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="khagrachari"){
                return el
            }
        })[0];
        const lakshmipurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="lakshmipur"){
                return el
            }
        })[0];
        const noakhaliDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="noakhali"){
                return el
            }
        })[0];
        const rangamatiDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="rangamati"){
                return el
            }
        })[0];

            //    khulna district

        const bagerhatDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="bagerhat"){
                return el
            }
        })[0];
        const chuadangaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="chuadanga"){
                return el
            }
        })[0];
        const jessoreDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="jessore"){
                return el
            }
        })[0];
        const jhenaidahDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="jhenaidah"){
                return el
            }
        })[0];
        const khulnaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="khulna"){
                return el
            }
        })[0];
        const kushtiaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="kushtia"){
                return el
            }
        })[0];
        const maguraDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="magura"){
                return el
            }
        })[0];
        const meherpurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="meherpur"){
                return el
            }
        })[0];
        const narailDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="narail"){
                return el
            }
        })[0];
        const satkhiraDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="satkhira"){
                return el
            }
        })[0];


        // Rajshahi division
        const bograDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="bogra"){
                return el
            }
        })[0];
        const joypurhatDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="joypurhat"){
                return el
            }
        })[0];
        const naogaonDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="naogaon"){
                return el
            }
        })[0];
        const natoreDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="natore"){
                return el
            }
        })[0];
        const nawabganjDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="nawabganj"){
                return el
            }
        })[0];
        const pabnaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="pabna"){
                return el
            }
        })[0];
        const rajshahiDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="rajshahi"){
                return el
            }
        })[0];
        const sirajgonjDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="sirajgonj"){
                return el
            }
        })[0];

        // Rangpur division district
        const dinajpurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="dinajpur"){
                return el
            }
        })[0];
        const gaibandhaDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="gaibandha"){
                return el
            }
        })[0];
        const kurigramDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="kurigram"){
                return el
            }
        })[0];
        const lalmonirhatDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="lalmonirhat"){
                return el
            }
        })[0];
        const nilphamariDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="nilphamari"){
                return el
            }
        })[0];
        const panchagarhDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="panchagarh"){
                return el
            }
        })[0];
        const rangpurDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="rangpur"){
                return el
            }
        })[0];
        const thakurgaonDistrict = type?.filter((el,i)=>{
            if(el.name.toLocaleLowerCase()==="thakurgaon"){
                return el
            }
        })[0];


            // sylhet division district
            const habiganjDistrict = type?.filter((el,i)=>{
                if(el.name.toLocaleLowerCase()==="habiganj"){
                    return el
                }
            })[0];
            const maulvibazarDistrict = type?.filter((el,i)=>{
                if(el.name.toLocaleLowerCase()==="maulvibazar"){
                    return el
                }
            })[0];
            const sunamganjDistrict = type?.filter((el,i)=>{
                if(el.name.toLocaleLowerCase()==="sunamganj"){
                    return el
                }
            })[0];
            const sylhetDistrict = type?.filter((el,i)=>{
                if(el.name.toLocaleLowerCase()==="sylhet"){
                    return el
                }
            })[0];

                // Mymenshign District
                const jamalpurDistrict = type?.filter((el,i)=>{
                    if(el.name.toLocaleLowerCase()==="jamalpur"){
                        return el
                    }
                })[0];
                const mymensinghDistrict = type?.filter((el,i)=>{
                    if(el.name.toLocaleLowerCase()==="mymensingh"){
                        return el
                    }
                })[0];
                const netrokonaDistrict = type?.filter((el,i)=>{
                    if(el.name.toLocaleLowerCase()==="netrokona"){
                        return el
                    }
                })[0];
                const sherpurDistrict = type?.filter((el,i)=>{
                    if(el.name.toLocaleLowerCase()==="sherpur"){
                        return el
                    }
                })[0];
               

        if(selectedDistrict === barisalDistrict?.bn_name){
            typeUpazila = barisalDistrict?.upazilas
        } else if (selectedDistrict === bargunaDistrict?.bn_name){
            typeUpazila = bargunaDistrict?.upazilas
        } else if (selectedDistrict === pirojpurDistrict?.bn_name){
            typeUpazila = pirojpurDistrict.upazilas
        }else if (selectedDistrict === bholaDistrict?.bn_name){
            typeUpazila = bholaDistrict?.upazilas
        } else if (selectedDistrict === jhalokatiDistrict?.bn_name){
            typeUpazila = jhalokatiDistrict?.upazilas
        }   else if (selectedDistrict === patuakhaliDistrict?.bn_name){
            typeUpazila = patuakhaliDistrict?.upazilas

        }   else if (selectedDistrict === dhakaDistrict?.bn_name){
                typeUpazila = dhakaDistrict?.upazilas
        }   else if (selectedDistrict === faridpurDistrict?.bn_name){
                typeUpazila = faridpurDistrict.upazilas
        }   else if (selectedDistrict === gazipurDistrict?.bn_name){
                typeUpazila = gazipurDistrict?.upazilas
        }   else if (selectedDistrict === gopalganjDistrict?.bn_name){
                typeUpazila = gopalganjDistrict?.upazilas
        }   else if (selectedDistrict === kishoreganjDistrict?.bn_name){
                typeUpazila = kishoreganjDistrict?.upazilas
        }   else if (selectedDistrict === madaripurDistrict?.bn_name){
                typeUpazila = madaripurDistrict?.upazilas
        }   else if (selectedDistrict === manikganjDistrict?.bn_name){
                typeUpazila = manikganjDistrict.upazilas
        }   else if (selectedDistrict === munshiganjDistrict?.bn_name){
                typeUpazila = munshiganjDistrict?.upazilas
        }   else if (selectedDistrict === narayanganjDistrict?.bn_name){
                typeUpazila = narayanganjDistrict?.upazilas
        }   else if (selectedDistrict === rajbariDistrict?.bn_name){
             typeUpazila = rajbariDistrict?.upazilas
        }    else if (selectedDistrict === narsingdiDistrict?.bn_name){
            typeUpazila = narsingdiDistrict.upazilas
    }   else if (selectedDistrict === shariatpurDistrict?.bn_name){
            typeUpazila = shariatpurDistrict?.upazilas
    }   else if (selectedDistrict === tangailDistrict?.bn_name){
            typeUpazila = tangailDistrict?.upazilas


    }   else if (selectedDistrict === chittagongDistrict?.bn_name){
            typeUpazila = chittagongDistrict?.upazilas
    }   else if (selectedDistrict === bandarbanDistrict?.bn_name){
            typeUpazila = bandarbanDistrict?.upazilas
    }   else if (selectedDistrict === brahmanbariaDistrict?.bn_name){
            typeUpazila = brahmanbariaDistrict.upazilas
    }   else if (selectedDistrict === chandpurDistrict?.bn_name){
            typeUpazila = chandpurDistrict?.upazilas
    }   else if (selectedDistrict === coxbazarDistrict?.bn_name){
            typeUpazila = coxbazarDistrict?.upazilas
    }   else if (selectedDistrict === feniDistrict?.bn_name){
         typeUpazila = feniDistrict?.upazilas
    }  else if (selectedDistrict === comillaDistrict?.bn_name){
         typeUpazila = comillaDistrict?.upazilas
    } 
     
       else if (selectedDistrict === khagrachariDistrict?.bn_name){
        typeUpazila = khagrachariDistrict?.upazilas
}   else if (selectedDistrict === lakshmipurDistrict?.bn_name){
        typeUpazila = lakshmipurDistrict?.upazilas
}   else if (selectedDistrict === noakhaliDistrict?.bn_name){
        typeUpazila = noakhaliDistrict.upazilas
}   else if (selectedDistrict === rangamatiDistrict?.bn_name){
        typeUpazila = rangamatiDistrict?.upazilas

}   else if (selectedDistrict === bagerhatDistrict?.bn_name){
        typeUpazila = bagerhatDistrict?.upazilas
}   else if (selectedDistrict === chuadangaDistrict?.bn_name){
     typeUpazila = chuadangaDistrict?.upazilas
} else if (selectedDistrict === jessoreDistrict?.bn_name){
        typeUpazila = jessoreDistrict?.upazilas
}   else if (selectedDistrict === jhenaidahDistrict?.bn_name){
     typeUpazila = jhenaidahDistrict?.upazilas
} else if (selectedDistrict === khulnaDistrict?.bn_name){
    typeUpazila = khulnaDistrict?.upazilas
}   else if (selectedDistrict === kushtiaDistrict?.bn_name){
 typeUpazila = kushtiaDistrict?.upazilas
} else if (selectedDistrict === maguraDistrict?.bn_name){
    typeUpazila = maguraDistrict?.upazilas
}   else if (selectedDistrict === meherpurDistrict?.bn_name){
 typeUpazila = meherpurDistrict?.upazilas
} else if (selectedDistrict === narailDistrict?.bn_name){
    typeUpazila = narailDistrict?.upazilas
}   else if (selectedDistrict === satkhiraDistrict?.bn_name){
 typeUpazila = satkhiraDistrict?.upazilas
} 

    else if (selectedDistrict === bograDistrict?.bn_name){
    typeUpazila = bograDistrict?.upazilas
}   else if (selectedDistrict === joypurhatDistrict?.bn_name){
    typeUpazila = joypurhatDistrict?.upazilas
}   else if (selectedDistrict === naogaonDistrict?.bn_name){
    typeUpazila = naogaonDistrict.upazilas
}   else if (selectedDistrict === natoreDistrict?.bn_name){
    typeUpazila = natoreDistrict?.upazilas
}   else if (selectedDistrict === nawabganjDistrict?.bn_name){
    typeUpazila = nawabganjDistrict?.upazilas
}   else if (selectedDistrict === pabnaDistrict?.bn_name){
 typeUpazila = pabnaDistrict?.upazilas
} 
else if (selectedDistrict === rajshahiDistrict?.bn_name){
typeUpazila = rajshahiDistrict?.upazilas
}   else if (selectedDistrict === sirajgonjDistrict?.bn_name){
typeUpazila = sirajgonjDistrict?.upazilas
}   else if (selectedDistrict === dinajpurDistrict?.bn_name){
typeUpazila = dinajpurDistrict?.upazilas
}   else if (selectedDistrict === gaibandhaDistrict?.bn_name){
typeUpazila = gaibandhaDistrict?.upazilas

}   else if (selectedDistrict === kurigramDistrict?.bn_name){
typeUpazila = kurigramDistrict?.upazilas
}   else if (selectedDistrict === lalmonirhatDistrict?.bn_name){
typeUpazila = lalmonirhatDistrict?.upazilas
} else if (selectedDistrict === nilphamariDistrict?.bn_name){
typeUpazila = nilphamariDistrict?.upazilas
}   else if (selectedDistrict === panchagarhDistrict?.bn_name){
typeUpazila = panchagarhDistrict?.upazilas
} else if (selectedDistrict === rangpurDistrict?.bn_name){
typeUpazila = rangpurDistrict?.upazilas
}   else if (selectedDistrict === thakurgaonDistrict?.bn_name){
typeUpazila = thakurgaonDistrict?.upazilas
} else if (selectedDistrict === habiganjDistrict?.bn_name){
typeUpazila = habiganjDistrict?.upazilas
}   else if (selectedDistrict === maulvibazarDistrict?.bn_name){
typeUpazila = maulvibazarDistrict?.upazilas
} else if (selectedDistrict === sunamganjDistrict?.bn_name){
typeUpazila = sunamganjDistrict?.upazilas
}   else if (selectedDistrict === sylhetDistrict?.bn_name){
typeUpazila = sylhetDistrict?.upazilas
}  else if (selectedDistrict === jamalpurDistrict?.bn_name){
    typeUpazila = jamalpurDistrict?.upazilas
    }   else if (selectedDistrict === mymensinghDistrict?.bn_name){
    typeUpazila = mymensinghDistrict?.upazilas
    } else if (selectedDistrict === netrokonaDistrict?.bn_name){
    typeUpazila = netrokonaDistrict?.upazilas
    }   else if (selectedDistrict === sherpurDistrict?.bn_name){
    typeUpazila = sherpurDistrict?.upazilas
    } 
    

    if (typeUpazila) {
            optionsUpazila = typeUpazila.map((u,i) => <option key={i}>{u.bn_name}</option>);
        }
        const divisionData = bd_division.map((item)=> {return(<option>{item.bn_name}</option>)})

const handleSubmit = async (values) => {

    setLooding(true);
    let nidUrl="";
    let nidPicbackUrl="";
    let shopUrl="";
    let tradeLiUrl="";
    
 if( values.nidPicFront &&  values.nidPicFront.name) {
        const images = firebase.storage().ref().child(values.nidPicFront.name);
        await images.put(values.nidPicFront);
        nidUrl=await images.getDownloadURL()
 }
 if( values.nidPicback &&  values.nidPicback.name) {
    const images = firebase.storage().ref().child(values.nidPicback.name);
    await images.put(values.nidPicback);
    nidPicbackUrl=await images.getDownloadURL()
}

 if( values.shopPic &&  values.shopPic.name) {
    const images = firebase.storage().ref().child(values.shopPic.name);
    await images.put(values.shopPic);
    shopUrl = await images.getDownloadURL()
}

if( values.tradeLicensePic &&  values.tradeLicensePic.name) {
    const images = firebase.storage().ref().child(values.tradeLicensePic.name);
    await images.put(values.tradeLicensePic);
    tradeLiUrl= await images.getDownloadURL()
}
      if(values){ 
      try{
           await firebase.firestore().collection("merchant-registration").add({
          createdAt: new Date(),
          name: values.name,
          email: values.email,
          mobile: values.mobile,
          shopAddress: values.shopAddress,
          division: selectedDivision,
          district: selectedDistrict,
          upozila: values.upozila,
          nidPicFront: nidUrl,
          nidPicback: nidPicbackUrl,
          shopPic: shopUrl,
          tradeLicensePic:tradeLiUrl,
          deliveryDetails:values.deliveryDetails

        });
        setTimeout(() => {
            setLooding(false);
            setShow("আপনার তথ্য গুলো সফল ভাবে সংরক্ষণ করা হয়েছে ।")
            setShowError('')
            addToast('সফল হয়েছে !', { appearance: 'success',
            autoDismiss: true,
        });
        }, 800);
    }catch(e){
        setLooding(false);
        setShowError("দুঃখিত! আপনার তথ্য গুলো সংরক্ষণ হয়নি,পুনরায় চেষ্টা করুন!")
        setShow('')
   addToast("ব্যার্থ চেষ্টা !", { appearance: 'error',autoDismiss: true });
    }
}else{
    setLooding(false);
    setShowError("দুঃখিত! আপনার তথ্য গুলো সংরক্ষণ হয়নি,পুনরায় চেষ্টা করুন!")
    setShow('')
    addToast("ব্যার্থ চেষ্টা !", { appearance: 'error',autoDismiss: true});
}

};
    const formik = useFormik({
        initialValues: {
          name: "",
          email: "",
          mobile: "",
          shopAddress:"",
        //   division:"",
        //   district:"",
          upozila:"",
          deliveryDetails:"",
          nidPicFront:"",
          nidPicback:"",
          shopPic:"",
          tradeLicensePic:""

        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("আপনার নাম প্রয়োজন"),
            // email: Yup.string().email("ইমেইলটি সঠিক নয়").required("আপনার ইমেইল প্রয়োজন"),
            mobile: Yup.string().required("মোবাইল /ফোন নম্বর দিন"),
            shopAddress: Yup.string().required("দোকানের ঠিকানা উল্লেখ করুন"),
            deliveryDetails: Yup.string().required("বিস্তারিত লিখুন"),
            // division: Yup.string().required("বিভাগ / সিটি করপোরেশন উল্লেখ করুন"),
            // district: Yup.string().required("জেলা উল্লেখ করুন"),
            upozila: Yup.string().required("উপজেলা / থানা উল্লেখ করুন"),
            nidPicFront: Yup.mixed().required("জাতীয় পরিচয় পত্রের সম্মুখভাগের ছবি দিন"),
            // shopPic: Yup.mixed().required("দোকানের ছবি দিন"),
            // tradeLicensePic: Yup.mixed().required("ট্রেড লাইসেন্স ছবি দিন"),
        }),
        onSubmit: (values) => {
         handleSubmit(values);
        }
      });
    return (
        <>
        {Looding ? (<div className="spinners"><Spinner animation="border" /></div>) : (
            <>
           <section className="section-padding">
               <div className="container">
                   <div className="row justify-content-center">
                       <div className="col-lg-8">
                           <div className="section-title">
                               <h2 className="animate__animated animate__bounce animate__fadeInDown">   <span>মার্চেন্ট</span> রেজিষ্ট্রেশন</h2>
                           </div>
                       </div>
                       <div className="col-lg-5">
                           <div className="section-title">
                            <p style={{textAlign:'center',color:"#7857fe"}}>{show}</p>
                            <p style={{color:"red",textAlign:"center"}}>{showError}</p>
                           </div>
                       </div>
                   </div>
                   <div className="row jutify-content-center">
                   <div className="col-lg-3 col-md-5">
                       </div>
                       <div className="col-lg-9 col-lg-7">
                            <div className="contact-form">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <input 
                                                type="text"
                                                name="name" 
                                                placeholder="নাম" 
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name} 
                                                />
                                                  {formik.touched.name && formik.errors.name ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.name}</span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            </div>
                                     <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <input 
                                                type="email"
                                                name="email" 
                                                placeholder="ইমেইল" 
                                                className="form-control" 
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <input 
                                                type="text" 
                                                placeholder="মোবাইল / ফোন নাম্বার" 
                                                className="form-control"
                                                name="mobile"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile}  
                                                />
                                                {formik.touched.mobile && formik.errors.mobile ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.mobile}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                            {/* <label style={{marginLeft:"3%"}}>বিভাগ /সিটি করপোরেশন<span style={{color:"red"}}>*</span></label> */}
                                                <select
                                                className="form-select"
                                                name="division"
                                                onChange={handleChangesSelectedDivision}
                                                // onBlur={formik.handleBlur}
                                                // value={formik.values.division} 
                                                >
                                                 <option selected>বিভাগ / সিটি করপোরেশন</option>
                                                 {divisionData}
                                                </select>
                                                {formik.touched.division && formik.errors.division ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.division}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                            {/* <label style={{marginLeft:"5%"}}>জেলা<span style={{color:"red"}}>*</span></label> */}
                                                <select 
                                                className="form-select"
                                                name="district"
                                                onChange={handleChangesSelectedDistrict}
                                                // onBlur={formik.handleBlur}
                                                // value={formik.values.district} 
                                                >
                                                    <option selected>জেলা</option>
                                                   {options}
                                                </select>
                                                {formik.touched.district && formik.errors.district ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.district}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                {/* <label style={{marginLeft:"5%"}}>উপজেলা / থানা <span style={{color:"red"}}>*</span></label> */}
                                                <select 
                                                className="form-select"
                                                name="upozila"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.upozila} 
                                                >
                                                    <option selected>উপজেলা / থানা</option>
                                                    {optionsUpazila}
                                              
                                                </select>
                                                {formik.touched.upozila && formik.errors.upozila ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.upozila}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <input 
                                                type="text"
                                                name="shopAddress" 
                                                placeholder="আপনার দোকানের ঠিকানা" 
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.shopAddress} 
                                                />
                                                  {formik.touched.shopAddress && formik.errors.shopAddress ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.shopAddress}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <textarea 
                                                type="text"
                                                name="deliveryDetails" 
                                                placeholder="হোম ডেলিভারি সুবিধা প্রদান করেন কি?  সংক্ষেপে লিখুন...." 
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.deliveryDetails} 
                                                />
                                                  {formik.touched.deliveryDetails && formik.errors.deliveryDetails ? (
                                                    <span style={{color:"red",marginLeft:"3%"}}>{formik.errors.deliveryDetails}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    
                                    </div>
                                        <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                            <label htmlFor="cna-file-upload" className="custom-file-upload">
                                                    {" জাতীয় পরিচয় পত্রের সম্মুখভাগের ছবি"} <br/>
                                                    <i className="fas fa-cloud-upload-alt" style={{color:"#7857fe",fontSize:"48px"}} ></i>
                                                <input 
                                                    hidden
                                                    id="cna-file-upload" 
                                                    type="file"
                                                    name="nidPicFront"  
                                                    multiple
                                                    autoFocus
                                                    className="form-control-lg"
                                                    onChange={(event) => {
                                                        formik.setFieldValue("nidPicFront", event.currentTarget.files[0])}}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <p>{formik.values.nidPicFront.name}</p>
                                                   </label> <br/>
                                                   {formik.touched.nidPicFront && formik.errors.nidPicFront ? (
                                                    <span style={{color:"red",marginLeft:"4%"}}>{formik.errors.nidPicFront}</span>
                                                    ) : null}
                                                   
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                            <label htmlFor="nid-file-upload" className="custom-file-upload">
                                                    {" জাতীয় পরিচয় পত্রের বিপরীত পাশের  ছবি"} <br/>
                                                    <i className="fas fa-cloud-upload-alt" style={{color:"#7857fe",fontSize:"48px"}} ></i>
                                                <input 
                                                    hidden
                                                    id="nid-file-upload" 
                                                    type="file"
                                                    name="nidPicback"  
                                                    multiple
                                                    autoFocus
                                                    className="form-control-lg"
                                                    onChange={(event) => {
                                                        formik.setFieldValue("nidPicback", event.currentTarget.files[0])}}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <p>{formik.values.nidPicback.name}</p>
                                                   </label> <br/>
                                                   {/* {formik.touched.nidPicback && formik.errors.nidPicback ? (
                                                    <span style={{color:"red",marginLeft:"4%"}}>{formik.errors.nidPicback}</span>
                                                    ) : null}    */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                 <label htmlFor="shop-file-upload" className="custom-file-upload">
                                                    {" দোকানের ছবি"}
                                                    <br/>
                                                    <i className="fas fa-cloud-upload-alt" style={{color:"#7857fe",fontSize:"48px"}} ></i>
                                                
                                                <input 
                                                hidden 
                                                id="shop-file-upload" 
                                                type="file"
                                                name="shopPic"
                                                autoFocus 
                                                multiple
                                                className="form-control-lg"
                                                onChange={(event) => {
                                                    formik.setFieldValue("shopPic", event.currentTarget.files[0])}}
                                                onBlur={formik.handleBlur}
                                                />
                                                    <p>{formik.values.shopPic.name}</p>
                                                </label><br/>
                                                   {formik.touched.shopPic && formik.errors.shopPic ? (
                                                    <span style={{color:"red",marginLeft:"4%"}}>{formik.errors.shopPic}</span>
                                                    ) : null}
                                                
                                             </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="trad-file-upload" className="custom-file-upload">
                                                    {" ট্রেড লাইসেন্স ছবি"}
                                                    <br/>
                                                    <i className="fas fa-cloud-upload-alt" style={{color:"#7857fe",fontSize:"48px"}} ></i>
                                                
                                                <input 
                                                hidden 
                                                id="trad-file-upload" 
                                                type="file"
                                                name="tradeLicensePic"
                                                autoFocus
                                                multiple
                                                className="form-control-lg"
                                                onChange={(event) => {
                                                    formik.setFieldValue("tradeLicensePic", event.currentTarget.files[0])}}
                                                onBlur={formik.handleBlur}
                                                />
                                                    <p>{formik.values.tradeLicensePic.name}</p>
                                               </label><br/>
                                                   {formik.touched.tradeLicensePic && formik.errors.tradeLicensePic ? (
                                                    <span style={{color:"red",marginLeft:"4%"}}>{formik.errors.tradeLicensePic}</span>
                                                    ) : null}
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                           <button type="submit" className="btn btn-1" style={{marginLeft:"6%"}}>সংরক্ষণ করুন</button>
                                            </div>
                                        </div>
                                </form>
                            </div>
                       </div>
                   </div>
               </div>
           </section>
           <Footer />
           </>
        )}
        </>
    )
}

export default Register

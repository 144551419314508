
  export const bd_division = [
      {
        "name": "Barisal",
        "bn_name": "বরিশাল",
        "districts": [
          {
            "name": "Barguna",
            "bn_name": "বরগুনা",
            "lon": "90.1077819",
            "lat": "22.1590093",
            "upazilas": [
              {
                "name": "Amtali",
                "bn_name": "আমতলী"
              },
              {
                "name": "Bamna ",
                "bn_name": "বামনা"
              },
              {
                "name": "Barguna Sadar ",
                "bn_name": "বরগুনা সদর"
              },
              {
                "name": "Betagi ",
                "bn_name": "বেতাগী"
              },
              {
                "name": "Patharghata ",
                "bn_name": "পাথরঘাটা"
              },
              {
                "name": "Taltali ",
                "bn_name": "তালতলি"
              }
            ]
          },
          {
            "name": "Barisal",
            "bn_name": "বরিশাল",
            "lon": "90.318699",
            "lat": "22.6954585",
            "upazilas": [
              {
                "name": "Muladi ",
                "bn_name": "মুলাদী"
              },
              {
                "name": "Babuganj ",
                "bn_name": "বাবুগঞ্জ"
              },
              {
                "name": "Agailjhara ",
                "bn_name": "আগৈলঝাড়া"
              },
              {
                "name": "Barisal Sadar ",
                "bn_name": "বরিশাল সদর"
              },
              {
                "name": "Bakerganj ",
                "bn_name": "বাকেরগঞ্জ"
              },
              {
                "name": "Banaripara ",
                "bn_name": "বানারীপাড়া"
              },
              {
                "name": "Gaurnadi ",
                "bn_name": "গৌরনদী"
              },
              {
                "name": "Hizla ",
                "bn_name": "হিজলা"
              },
              {
                "name": "Mehendiganj ",
                "bn_name": "মেহেন্দিগঞ্জ "
              },
              {
                "name": "Wazirpur ",
                "bn_name": "উজিরপুর"
              },
              // {
              //   "name": "Airport",
              //   "bn_name": "à¦à§Ÿà¦¾à¦°à¦ªà§‹à¦°à§à¦Ÿ"
              // },
              // {
              //   "name": "Kawnia",
              //   "bn_name": "à¦•à¦¾à¦‰à¦¨à¦¿à§Ÿà¦¾"
              // },
              // {
              //   "name": "Bondor",
              //   "bn_name": "à¦¬à¦¨à§à¦¦à¦°"
              // },
              // {
              //   "name": "Others",
              //   "bn_name": "à¦…à¦¨à§à¦¯à¦¾à¦¨à§à¦¯"
              // }
            ]
          },
          {
            "name": "Bhola",
            "bn_name": "ভোলা",
            "lon": "90.648179",
            "lat": "22.685923",
            "upazilas": [
              {
                "name": "Bhola Sadar ",
                "bn_name": "ভোলা সদর"
              },
              {
                "name": "Burhanuddin ",
                "bn_name": "বোরহান উদ্দিন"
              },
              {
                "name": "Char Fasson ",
                "bn_name": "চরফ্যাশন"
              },
              {
                "name": "Daulatkhan ",
                "bn_name": "দৌলতখান"
              },
              {
                "name": "Lalmohan ",
                "bn_name": "লালমোহন"
              },
              {
                "name": "Manpura ",
                "bn_name": "মনপুরা"
              },
              {
                "name": "Tazumuddin ",
                "bn_name": "তজুমদ্দিন"
              }
            ]
          },
          {
            "name": "Jhalokati",
            "bn_name": "ঝালকাঠি",
            "lon": "90.1778663",
            "lat": "22.5629931",
            "upazilas": [
              {
                "name": "Jhalokati Sadar ",
                "bn_name": "ঝালকাঠি সদর"
              },
              {
                "name": "Kathalia ",
                "bn_name": "কাঠালিয়া"
              },
              {
                "name": "Nalchity ",
                "bn_name": "নলছিটি"
              },
              {
                "name": "Rajapur ",
                "bn_name": "রাজাপুর"
              }
            ]
          },
          {
            "name": "Patuakhali",
            "bn_name": "পটুয়াখালী",
            "lon": "90.3298712",
            "lat": "22.3596316",
            "upazilas": [
              {
                "name": "Bauphal ",
                "bn_name": "বাউফল"
              },
              {
                "name": "Dashmina ",
                "bn_name": "দশমিনা"
              },
              {
                "name": "Galachipa ",
                "bn_name": "গলাচিপা"
              },
              {
                "name": "Kalapara ",
                "bn_name": "কলাপাড়া"
              },
              {
                "name": "Mirzaganj ",
                "bn_name": "মির্জাগঞ্জ"
              },
              {
                "name": "Patuakhali Sadar ",
                "bn_name": "পটুয়াখালী সদর"
              },
              {
                "name": "Dumki ",
                "bn_name": "দুমকি"
              },
              {
                "name": "Rangabali ",
                "bn_name": "রাঙ্গাবালী"
              }
            ]
          },
          {
            "name": "Pirojpur",
            "bn_name": "পিরোজপুর",
            "lon": "89.7463804",
            "lat": "22.5087194",
            "upazilas": [
              {
                "name": "Bhandaria",
                "bn_name": " ভান্ডারিয়া"
              },
              {
                "name": "Kaukhali",
                "bn_name": "কাউখালী"
              },
              {
                "name": "Mathbaria",
                "bn_name": "মঠবাড়ীয়া"
              },
              {
                "name": "Nazirpur",
                "bn_name": "নাজিরপুর"
              },
              {
                "name": "Nesarabad",
                "bn_name": "নেছারাবাদ"
              },
              {
                "name": "Pirojpur Sadar",
                "bn_name": "পিরোজপুর সদর"
              },
              {
                "name": "Zianagar",
                "bn_name": "ইন্দুরকানী"
              }
            ]
          }
        ]
      },
      {
        "name": "Chittagong",
        "bn_name": "চট্টগ্রাম",
        "districts": [
          {
            "name": "Bandarban",
            "bn_name": "বান্দরবান",
            "lon": "92.2183773",
            "lat": "22.1953275",
            "upazilas": [
              {
                "name": "Bandarban Sadar",
                "bn_name": "বান্দরবান সদর "
              },
              {
                "name": "Thanchi",
                "bn_name": "থানচি"
              },
              {
                "name": "Lama",
                "bn_name": "লামা"
              },
              {
                "name": "Naikhongchhari",
                "bn_name": "নাইক্ষ্যংছড়ি "
              },
              {
                "name": "Ali kadam",
                "bn_name": "আলীকদম"
              },
              {
                "name": "Bowangchhari",
                "bn_name": "রোয়াংছড়ি "
              },
              {
                "name": "Ruma",
                "bn_name": "রুমা"
              }
            ]
          },
          {
            "name": "Brahmanbaria",
            "bn_name": "ব্রাহ্মণবাড়িয়া",
            "lon": "91.1119286",
            "lat": "23.9570904",
            "upazilas": [
              {
                "name": "Brahmanbaria Sadar ",
                "bn_name": "ব্রাহ্মণবাড়িয়া সদর"
              },
              {
                "name": "Ashuganj ",
                "bn_name": "আশুগঞ্জ"
              },
              {
                "name": "Nasirnagar ",
                "bn_name": "নাসিরনগর"
              },
              {
                "name": "Nabinagar ",
                "bn_name": "নবীনগর"
              },
              {
                "name": "Sarail ",
                "bn_name": "সরাইল"
              },
              {
                "name": "Kasba ",
                "bn_name": "কসবা"
              },
              {
                "name": "Akhaura ",
                "bn_name": "আখাউড়া"
              },
              {
                "name": "Bancharampur ",
                "bn_name": "বাঞ্ছারামপুর"
              },
              {
                "name": "Bijoynagar ",
                "bn_name": "বিজয়নগর"
              }
            ]
          },
          {
            "name": "Chandpur",
            "bn_name": "চাঁদপুর",
            "lon": "90.6712912",
            "lat": "23.2332585",
            "upazilas": [
              {
                "name": "Chandpur Sadar",
                "bn_name": "চাঁদপুর সদর"
              },
              {
                "name": "Faridganj",
                "bn_name": "ফরিদগঞ্জ"
              },
              {
                "name": "Haimchar",
                "bn_name": "হাইমচর"
              },
              {
                "name": "Haziganj",
                "bn_name": "হাজীগঞ্জ"
              },
              {
                "name": "Kachua",
                "bn_name": "কচুয়া"
              },
              {
                "name": "Matlab Uttar",
                "bn_name": "মতলব উত্তর"
              },
              {
                "name": "Matlab Dakkhin",
                "bn_name": "মতলব দক্ষিণ"
              },
              {
                "name": "Shahrasti",
                "bn_name": "শাহরাস্তি"
              }
            ]
          },
          {
            "name": "Chittagong",
            "bn_name": "চট্টগ্রাম",
            "lon": "91.834073",
            "lat": "22.335109",
            "upazilas": [
              {
                "name": "Anwara ",
                "bn_name": "আনোয়ারা"
              },
              {
                "name": "Banshkhali ",
                "bn_name": "বাঁশখালী"
              },
              {
                "name": "Boalkhali ",
                "bn_name": "বোয়ালখালী"
              },
              {
                "name": "Chandanaish ",
                "bn_name": "চন্দনাইশ"
              },
              {
                "name": "Fatikchhari ",
                "bn_name": "ফটিকছড়ি"
              },
              {
                "name": "Hathazari ",
                "bn_name": "হাটহাজারী"
              },
              {
                "name": "Lohagara ",
                "bn_name": "লোহাগাড়া"
              },
              {
                "name": "Mirsharai ",
                "bn_name": "মীরসরাই"
              },
              {
                "name": "Patiya ",
                "bn_name": "পটিয়া"
              },
              {
                "name": "Rangunia ",
                "bn_name": "রাঙ্গুনিয়া"
              },
              {
                "name": "Raozan ",
                "bn_name": "রাউজান"
              },
              {
                "name": "Sandwip ",
                "bn_name": "সন্দ্বীপ"
              },
              {
                "name": "Satkania ",
                "bn_name": "সাতকানিয়া"
              },
              {
                "name": "Sitakunda ",
                "bn_name": "সীতাকুন্ড"
              },
              {
                "name": "Akborsha",
                "bn_name": "আকবর শাহ"
              },
              {
                "name": "Baijid",
                "bn_name": "বায়েজিদ "
              },
              {
                "name": "Bakolia",
                "bn_name": "বাক লিয়া"
              },
              {
                "name": "Bandar",
                "bn_name": "বন্দর"
              },
              {
                "name": "Chandgaon",
                "bn_name": "চাঁদ গাঁ"
              },
              {
                "name": "Doublemooring",
                "bn_name": "ডাবল মোড়িং"
              },
              {
                "name": "EPZ",
                "bn_name": "ইপিজেড"
              },
              {
                "name": "Hali Shohor",
                "bn_name": "হালিশহর"
              },
              {
                "name": "Kornafuli",
                "bn_name": "কর্ণফুলী"
              },
              // {
              //   "name": "Kotwali",
              //   "bn_name": "à¦•à§‹à¦¤à§‹à¦¯à¦¼à¦¾à¦²à§€"
              // },
              {
                "name": "Kulshi",
                "bn_name": "কালশি"
              },
              // {
              //   "name": "Pahartali",
              //   "bn_name": "à¦ªà¦¾à¦¹à¦¾à¦¡à¦¼à¦¤à¦²à§€"
              // },
              // {
              //   "name": "Panchlaish",
              //   "bn_name": "à¦ªà¦¾à¦à¦šà¦²à¦¾à¦‡à¦¶"
              // },
              // {
              //   "name": "Potenga",
              //   "bn_name": "à¦ªà¦¤à§‡à¦™à§à¦—à¦¾"
              // },
              // {
              //   "name": "Shodhorgat",
              //   "bn_name": "à¦¸à¦¦à¦°à¦˜à¦¾à¦Ÿ"
              // },
              {
                "name": "Airport",
                "bn_name": "এয়ারপোর্ট"
              }
            ]
          },
          {
            "name": "Comilla",
            "bn_name": "কুমিল্লা",
            "lon": "91.1788135",
            "lat": "23.4682747",
            "upazilas": [
              {
                "name": "Barura ",
                "bn_name": "বরুড়া লালমাই"
              },
              {
                "name": "Brahmanpara ",
                "bn_name": "ব্রাহ্মণপাড়া"
              },
              {
                "name": "Burichong ",
                "bn_name": "বুড়িচং"
              },
              {
                "name": "Chandina ",
                "bn_name": "চান্দিনা"
              },
              {
                "name": "Chauddagram ",
                "bn_name": "চৌদ্দগ্রাম"
              },
              {
                "name": "Daudkandi ",
                "bn_name": "দাউদকান্দি"
              },
              {
                "name": "Debidwar ",
                "bn_name": "দেবিদ্বার"
              },
              {
                "name": "Homna ",
                "bn_name": "হোমনা"
              },
              {
                "name": "Comilla Sadar ",
                "bn_name": "কুমিল্লা সদর"
              },
              {
                "name": "Laksam ",
                "bn_name": "লাকসাম"
              },
              {
                "name": "Monohorgonj ",
                "bn_name": "মনোহরগঞ্জ"
              },
              {
                "name": "Meghna ",
                "bn_name": "মেঘনা"
              },
              {
                "name": "Muradnagar ",
                "bn_name": "মুরাদনগর"
              },
              {
                "name": "Nangalkot ",
                "bn_name": "নাঙ্গলকোট"
              },
              {
                "name": "Comilla Sadar South ",
                "bn_name": "কুমিল্লা সদর দক্ষিণ"
              },
              {
                "name": "Titas ",
                "bn_name": "তিতাস"
              },
              {
                "name": "Others",
                "bn_name": "অন্যান্য"
              }
            ]
          },
          {
            "name": "Cox's Bazar",
            "bn_name": "কক্সবাজার",
            "lon": "91.9328621",
            "lat": "21.4508836",
            "upazilas": [
              {
                "name": "Chakaria ",
                "bn_name": "চকরিয়া"
              },
              {
                "name": "Cox's Bazar Sadar ",
                "bn_name": "কক্সবাজার সদর"
              },
              {
                "name": "Kutubdia ",
                "bn_name": "কুতুবদিয়া"
              },
              {
                "name": "Maheshkhali ",
                "bn_name": "মহেশখালী"
              },
              {
                "name": "Ramu ",
                "bn_name": "রামু"
              },
              {
                "name": "Teknaf ",
                "bn_name": "টেকনাফ"
              },
              {
                "name": "Ukhia ",
                "bn_name": "উখিয়া"
              },
              {
                "name": "Pekua ",
                "bn_name": "পেকুয়া"
              }
            ]
          },
          {
            "name": "Feni",
            "bn_name": "ফেনী",
            "lon": "91.3840844",
            "lat": "23.023231",
            "upazilas": [
              {
                "name": "Feni Sadar",
                "bn_name": "ফেনী সদর"
              },
              {
                "name": "Chagalnaiya",
                "bn_name": "ছাগলনাইয়া"
              },
              {
                "name": "Daganbhyan",
                "bn_name": "দাগনভূঞা"
              },
              {
                "name": "Parshuram",
                "bn_name": "পরশুরাম"
              },
              {
                "name": "Fhulgazi",
                "bn_name": "ফুলগাজী"
              },
              {
                "name": "Sonagazi",
                "bn_name": "সোনাগাজী"
              }
            ]
          },
          {
            "name": "Khagrachari",
            "bn_name": "খাগড়াছড়ি",
            "lon": "91.984663",
            "lat": "23.119285",
            "upazilas": [
              {
                "name": "Dighinala ",
                "bn_name": "দিঘীনালা"
              },
              {
                "name": "Khagrachhari ",
                "bn_name": "খাগড়াছড়ি সদর"
              },
              {
                "name": "Lakshmichhari ",
                "bn_name": "লক্ষীছড়ি"
              },
              {
                "name": "Mahalchhari ",
                "bn_name": "মহালছড়ি"
              },
              {
                "name": "Manikchhari ",
                "bn_name": "মানিকছড়ি"
              },
              {
                "name": "Matiranga ",
                "bn_name": "মাটিরাঙ্গা"
              },
              {
                "name": "Panchhari ",
                "bn_name": "পানছড়ি"
              },
              {
                "name": "Ramgarh ",
                "bn_name": "রামগড়"
              },
              {
                "name":"Goimara",
                "bn_name":"গুইমারা"
              }
            ]
          },
          {
            "name": "Lakshmipur",
            "bn_name": "লক্ষ্মীপুর",
            "lon": "90.841184",
            "lat": "22.942477",
            "upazilas": [
              {
                "name": "Lakshmipur Sadar ",
                "bn_name": "লক্ষ্মীপুর সদর"
              },
              {
                "name": "Raipur ",
                "bn_name": "রায়পুর"
              },
              {
                "name": "Ramganj ",
                "bn_name": "রামগঞ্জ"
              },
              {
                "name": "Ramgati ",
                "bn_name": "রামগতি"
              },
              {
                "name": "Komol Nagar ",
                "bn_name": "কমলনগর"
              }
            ]
          },
          {
            "name": "Noakhali",
            "bn_name": "নোয়াখালী",
            "lon": "91.099398",
            "lat": "22.869563",
            "upazilas": [
              {
                "name": "Noakhali Sadar ",
                "bn_name": "নোয়াখালী সদর"
              },
              {
                "name": "Begumganj ",
                "bn_name": "বেগমগঞ্জ"
              },
              {
                "name": "Chatkhil ",
                "bn_name": "চাটখিল"
              },
              {
                "name": "Companyganj ",
                "bn_name": "কোম্পানীগঞ্জ"
              },
              {
                "name": "Shenbag ",
                "bn_name": "সেনবাগ"
              },
              {
                "name": "Hatia ",
                "bn_name": "হাতিয়া"
              },
              {
                "name": "Kobirhat ",
                "bn_name": "কবিরহাট "
              },
              {
                "name": "Sonaimuri ",
                "bn_name": "সোনাইমুড়ী"
              },
              {
                "name": "Suborno Char ",
                "bn_name": "সুবর্ণচর"
              }
            ]
          },
          {
            "name": "Rangamati",
            "bn_name": "রাঙ্গামাটি",
            "lon": "92.1555518",
            "lat": "22.655798",
            "upazilas": [
              {
                "name": "Rangamati Sadar ",
                "bn_name": "রাঙ্গামাটি সদর"
              },
              {
                "name": "Belaichhari ",
                "bn_name": "বিলাইছড়ি"
              },
              {
                "name": "Bagaichhari ",
                "bn_name": "বাঘাইছড়ি"
              },
              {
                "name": "Barkal ",
                "bn_name": "বরকল"
              },
              {
                "name": "Juraichhari ",
                "bn_name": "জুরাছড়ি"
              },
              {
                "name": "Rajasthali ",
                "bn_name": "রাজস্থলী"
              },
              {
                "name": "Kaptai ",
                "bn_name": "কাপ্তাই"
              },
              {
                "name": "Langadu ",
                "bn_name": "লংগদু"
              },
              {
                "name": "Nannerchar ",
                "bn_name": "নানিয়ারচর "
              },
              {
                "name": "Kaukhali ",
                "bn_name": "কাউখালী"
              }
            ]
          }
        ]
      },
      {
        "name": "Dhaka",
        "bn_name": "ঢাকা",
        "districts": [
          {
            "name": "Dhaka",
            "bn_name": "ঢাকা",
            "lon": "90.4111451",
            "lat": "23.7115253",
            "upazilas": [
              {
                "name": "Adabor",
                "bn_name": "আদাবর"
              },
              {
                "name": "Airport",
                "bn_name": "বিমানবন্দর"
              },
              {
                "name": "Badda",
                "bn_name": "বাড্ডা "
              },
              {
                "name": "Banani",
                "bn_name": "বনানী "
              },
              {
                "name": "Bangshal",
                "bn_name": "বংশাল"
              },
              {
                "name": "Bhashantek",
                "bn_name": "ভাষানটেক"
              },
              {
                "name": "Cantonment",
                "bn_name": "ক্যান্টনমেন্ট"
              },
              {
                "name": "Chackbazar",
                "bn_name": "চকবাজার"
              },
              {
                "name": "Darussalam",
                "bn_name": "দারুস সালাম "
              },
              {
                "name": "Daskhinkhan",
                "bn_name": "দক্ষিণখান "
              },
              {
                "name": "Demra",
                "bn_name": "ডেমরা"
              },
              {
                "name": "Dhamrai",
                "bn_name": "ধামরাই"
              },
              {
                "name": "Dhanmondi",
                "bn_name": "ধানমন্ডি"
              },
              {
                "name": "Dohar",
                "bn_name": "দোহার"
              },
              {
                "name": "Gandaria",
                "bn_name": "গেন্ডারিয়া"
              },
              {
                "name": "Gulshan",
                "bn_name": "গুলশান"
              },
              {
                "name": "Hazaribag",
                "bn_name": "হাজারীবাগ"
              },
              {
                "name": "Jatrabari",
                "bn_name": "যাত্রাবাড়ী "
              },
              {
                "name": "Kafrul",
                "bn_name": "কাফরুল"
              },
              {
                "name": "Kalabagan",
                "bn_name": "কলাবাগান"
              },
              {
                "name": "Kamrangirchar",
                "bn_name": "কামরাঙ্গীরচর"
              },
              {
                "name": "Keraniganj",
                "bn_name": "কেরাণীগঞ্জ"
              },
              {
                "name": "Khilgaon",
                "bn_name": "খিলগাঁও"
              },
              {
                "name": "Khilkhet",
                "bn_name": "খিলক্ষেত"
              },
              {
                "name": "Kotwali",
                "bn_name": "কোতয়ালী "
              },
              {
                "name": "Lalbag",
                "bn_name": "লালবাগ"
              },
              {
                "name": "Mirpur Model",
                "bn_name": "মিরপুর"
              },
              {
                "name": "Mohammadpur",
                "bn_name": "মোহাম্মদপুর"
              },
              {
                "name": "Motijheel",
                "bn_name": "মতিঝিল"
              },
              {
                "name": "Mugda",
                "bn_name": "মুগদা "
              },
              {
                "name": "Nawabganj",
                "bn_name": "নবাবগঞ্জ"
              },
              {
                "name": "New Market",
                "bn_name": "নিউমার্কেট"
              },
              {
                "name": "Pallabi",
                "bn_name": "পল্লবী"
              },
              {
                "name": "Paltan",
                "bn_name": "পল্টন মডেল"
              },
              {
                "name": "Ramna",
                "bn_name": "রমনা মডেল "
              },
              {
                "name": "Rampura",
                "bn_name": "রামপুরা"
              },
              {
                "name": "Rupnagar",
                "bn_name": "রূপনগর"
              },
              {
                "name": "Sabujbag",
                "bn_name": "সবুজবাগ "
              },
              {
                "name": "Savar",
                "bn_name": "সাভার"
              },
              {
                "name": "Shah Ali",
                "bn_name": "শাহআলী"
              },
              {
                "name": "Shahbag",
                "bn_name": "শাহবাগ"
              },
              {
                "name": "Shahjahanpur",
                "bn_name": "শাহজাহানপুর"
              },
              {
                "name": "Sherebanglanagar",
                "bn_name": "শেরেবাংলা নগর"
              },
              {
                "name": "Shyampur",
                "bn_name": "শ্যামপুর"
              },
              {
                "name": "Sutrapur",
                "bn_name": "সূত্রাপুর"
              },
              {
                "name": "Tejgaon",
                "bn_name": "তেজঁগাও"
              },
              {
                "name": "Tejgaon I/A",
                "bn_name": "তেজঁগাও শিল্পাঞ্চল"
              },
              {
                "name": "Turag",
                "bn_name": "তুরাগ"
              },
              {
                "name": "Uttara",
                "bn_name": "উত্তরা মডেল"
              },
              {
                "name": "Uttara West",
                "bn_name": "উত্তারা পশ্চিম"
              },
              {
                "name": "Uttarkhan",
                "bn_name": "উত্তরখান"
              },
              {
                "name": "Vatara",
                "bn_name": "ভাটারা "
              },
              {
                "name": "Wari",
                "bn_name": "ওয়ারী "
              },
              {
                "name": "Others",
                "bn_name": "কদমতলী"
              }
            ]
          },
          {
            "name": "Faridpur",
            "bn_name": "ফরিদপুর",
            "lon": "89.8429406",
            "lat": "23.6070822",
            "upazilas": [
              {
                "name": "Faridpur Sadar ",
                "bn_name": "ফরিদপুর সদর"
              },
              {
                "name": "Boalmari ",
                "bn_name": "বোয়ালমারী"
              },
              {
                "name": "Alfadanga ",
                "bn_name": "আলফাডাঙ্গা"
              },
              {
                "name": "Madhukhali ",
                "bn_name": "মধুখালী"
              },
              {
                "name": "Bhanga ",
                "bn_name": "ভাঙ্গা"
              },
              {
                "name": "Nagarkanda ",
                "bn_name": "নগরকান্দা"
              },
              {
                "name": "Charbhadrasan ",
                "bn_name": "চরভদ্রাসন"
              },
              {
                "name": "Sadarpur ",
                "bn_name": "সদরপুর"
              },
              {
                "name": "Shaltha ",
                "bn_name": "সালথা"
              },
              // {
              //   "name": "Others",
              //   "bn_name": "à¦…à¦¨à§à¦¯à¦¾à¦¨à§à¦¯"
              // }
            ]
          },
          {
            "name": "Gazipur",
            "bn_name": "গাজীপুর",
            "lon": "90.4264283",
            "lat": "24.0022858",
            "upazilas": [
              {
                "name": "Gazipur Sadar-Joydebpur",
                "bn_name": "গাজীপুর সদর"
              },
              {
                "name": "Kaliakior",
                "bn_name": "কালিয়াকৈর"
              },
              {
                "name": "Kapasia",
                "bn_name": "কাপাসিয়া"
              },
              {
                "name": "Sripur",
                "bn_name": "শ্রীপুর"
              },
              {
                "name": "Kaliganj",
                "bn_name": "কালীগঞ্জ"
              },
              {
                "name": "Tongi",
                "bn_name": "টঙ্গী"
              }
            ]
          },
          {
            "name": "Gopalganj",
            "bn_name": "গোপালগঞ্জ",
            "lon": "89.8266059",
            "lat": "23.0050857",
            "upazilas": [
              {
                "name": "Gopalganj Sadar ",
                "bn_name": "গোপালগঞ্জ সদর"
              },
              {
                "name": "Kashiani ",
                "bn_name": "কাশিয়ানী"
              },
              {
                "name": "Kotalipara ",
                "bn_name": "কোটালীপাড়া"
              },
              {
                "name": "Muksudpur ",
                "bn_name": "মুকসুদপুর"
              },
              {
                "name": "Tungipara ",
                "bn_name": "টুংগীপাড়া"
              },
              // {
              //   "name": "Others",
              //   "bn_name": "à¦…à¦¨à§à¦¯à¦¾à¦¨à§à¦¯"
              // }
            ]
          },
          {
            "name": "Kishoreganj",
            "bn_name": "কিশোরগঞ্জ",
            "lon": "90.776575",
            "lat": "24.444937",
            "upazilas": [
              {
                "name": "Astagram ",
                "bn_name": "অষ্টগ্রাম"
              },
              {
                "name": "Bajitpur ",
                "bn_name": "বাজিতপুর"
              },
              {
                "name": "Bhairab ",
                "bn_name": "ভৈরব"
              },
              {
                "name": "Hossainpur ",
                "bn_name": "হোসেনপুর "
              },
              {
                "name": "Itna ",
                "bn_name": "ইটনা"
              },
              {
                "name": "Karimganj ",
                "bn_name": "করিমগঞ্জ"
              },
              {
                "name": "Katiadi ",
                "bn_name": "কটিয়াদী"
              },
              {
                "name": "Kishoreganj Sadar ",
                "bn_name": "কিশোরগঞ্জ সদর"
              },
              {
                "name": "Kuliarchar ",
                "bn_name": "কুলিয়ারচর"
              },
              {
                "name": "Mithamain ",
                "bn_name": "মিঠামইন"
              },
              {
                "name": "Nikli ",
                "bn_name": "নিকলী"
              },
              {
                "name": "Pakundia ",
                "bn_name": "পাকুন্দিয়া"
              },
              {
                "name": "Tarail ",
                "bn_name": "তাড়াইল"
              }
            ]
          },
          {
            "name": "Madaripur",
            "bn_name": "মাদারীপুর",
            "lon": "90.1896805",
            "lat": "23.164102",
            "upazilas": [
              {
                "name": "Madaripur Sadar",
                "bn_name": "মাদারীপুর সদর "
              },
              {
                "name": "Kalkini",
                "bn_name": "কালকিনি"
              },
              {
                "name": "Rajoir",
                "bn_name": "রাজৈর"
              },
              {
                "name": "Shibchar",
                "bn_name": "শিবচর"
              }
            ]
          },
          {
            "name": "Manikganj",
            "bn_name": "মানিকগঞ্জ",
            "lon": "89.9867312",
            "lat": "23.8655346",
            "upazilas": [
              {
                "name": "Manikganj Sadar ",
                "bn_name": "মানিকগঞ্জ সদর"
              },
              {
                "name": "Singair ",
                "bn_name": "সিংগাইর"
              },
              {
                "name": "Shibalaya ",
                "bn_name": "শিবালয়"
              },
              {
                "name": "Saturia ",
                "bn_name": "সাটুরিয়া"
              },
              {
                "name": "Harirampur ",
                "bn_name": "হরিরামপুর"
              },
              {
                "name": "Ghior ",
                "bn_name": "ঘিওর"
              },
              {
                "name": "Daulatpur ",
                "bn_name": "দৌলতপুর"
              }
            ]
          },
          {
            "name": "Munshiganj",
            "bn_name": "মুন্সিগঞ্জ",
            "lon": "90.1660068",
            "lat": "23.5257266,",
            "upazilas": [
              {
                "name": "Lohajang ",
                "bn_name": "লৌহজং"
              },
              {
                "name": "Sreenagar ",
                "bn_name": "শ্রীনগর"
              },
              {
                "name": "Munshiganj Sadar ",
                "bn_name": "মুন্সিগঞ্জ সদর"
              },
              {
                "name": "Sirajdikhan ",
                "bn_name": "সিরাজদিখান"
              },
              {
                "name": "Tongibari ",
                "bn_name": "টংগীবাড়ি"
              },
              {
                "name": "Gazaria ",
                "bn_name": "গজারিয়া"
              }
            ]
          },
          {
            "name": "Narayanganj",
            "bn_name": "নারায়ণগঞ্জ",
            "lon": "90.496482",
            "lat": "23.63366",
            "upazilas": [
              {
                "name": "Araihazar ",
                "bn_name": "আড়াইহাজার উপজেলা "
              },
              {
                "name": "Sonargaon ",
                "bn_name": "সোনারগাঁ উপজেলা"
              },
              {
                "name": "Bandar",
                "bn_name": "বন্দর উপজেলা"
              },
              {
                "name": "Naryanganj Sadar ",
                "bn_name": "নারায়নগঞ্জ সদর"
              },
              {
                "name": "Rupganj ",
                "bn_name": "রূপগঞ্জ উপজেলা"
              }
            ]
          },
          {
            "name": "Narsingdi",
            "bn_name": "নরসিংদী",
            "lon": "90.71541",
            "lat": "23.932233",
            "upazilas": [
              {
                "name": "Belabo ",
                "bn_name": "বেলাবো"
              },
              {
                "name": "Monohardi ",
                "bn_name": "মনোহরদী"
              },
              {
                "name": "Narsingdi Sadar ",
                "bn_name": "নরসিংদী সদর"
              },
              {
                "name": "Palash ",
                "bn_name": "পলাশ"
              },
              {
                "name": "Raipura , Narsingdi",
                "bn_name": "রায়পুরা"
              },
              {
                "name": "Shibpur ",
                "bn_name": "শিবপুর"
              }
            ]
          },
          {
            "name": "Rajbari",
            "bn_name": "রাজবাড়ী	",
            "lon": "89.6444665",
            "lat": "23.7574305",
            "upazilas": [
              {
                "name": "Baliakandi ",
                "bn_name": " বালিয়াকান্দি"
              },
              {
                "name": "Goalandaghat ",
                "bn_name": "গোয়ালন্দ"
              },
              {
                "name": "Pangsha ",
                "bn_name": "পাংশা"
              },
              {
                "name": "Kalukhali ",
                "bn_name": "কালুখালী"
              },
              {
                "name": "Rajbari Sadar ",
                "bn_name": "রাজবাড়ী সদর"
              }
            ]
          },
          {
            "name": "Shariatpur",
            "bn_name": "শরীয়তপুর",
            "lon": "90.3426701",
            "lat": "23.2190408",
            "upazilas": [
              {
                "name": "Shariatpur Sadar -Palong",
                "bn_name": "শরিয়তপুর সদর"
              },
              {
                "name": "Damudya ",
                "bn_name": "ডামুড্যা"
              },
              {
                "name": "Naria ",
                "bn_name": "নড়িয়া"
              },
              {
                "name": "Jajira ",
                "bn_name": "জাজিরা"
              },
              {
                "name": "Bhedarganj ",
                "bn_name": "ভেদরগঞ্জ"
              },
              {
                "name": "Gosairhat ",
                "bn_name": "গোসাইরহাট "
              }
            ]
          },
          {
            "name": "Tangail",
            "bn_name": "টাঙ্গাইল",
            "lon": "89.8820495",
            "lat": "24.2476913",
            "upazilas": [
              {
                "name": "Tangail Sadar ",
                "bn_name": "টাঙ্গাইল সদর"
              },
              {
                "name": "Sakhipur ",
                "bn_name": "সখিপুর"
              },
              {
                "name": "Basail ",
                "bn_name": "বাসাইল"
              },
              {
                "name": "Madhupur ",
                "bn_name": "মধুপুর"
              },
              {
                "name": "Ghatail ",
                "bn_name": "ঘাটাইল"
              },
              {
                "name": "Kalihati ",
                "bn_name": "কালিহাতী"
              },
              {
                "name": "Nagarpur ",
                "bn_name": "নাগরপুর"
              },
              {
                "name": "Mirzapur ",
                "bn_name": "মির্জাপুর"
              },
              {
                "name": "Gopalpur ",
                "bn_name": "গোপালপুর"
              },
              {
                "name": "Delduar ",
                "bn_name": "দেলদুয়ার"
              },
              {
                "name": "Bhuapur ",
                "bn_name": "ভুয়াপুর"
              },
              {
                "name": "Dhanbari ",
                "bn_name": "ধনবাড়ী"
              }
            ]
          }
        ]
      },
      {
        "name": "Khulna",
        "bn_name": "খুলনা",
        "districts": [
          {
            "name": "Bagerhat",
            "bn_name": "বাগেরহাট",
            "lon": "89.785938",
            "lat": "22.651568",
            "upazilas": [
              {
                "name": "Bagerhat Sadar ",
                "bn_name": "বাগেরহাট সদর"
              },
              {
                "name": "Chitalmari ",
                "bn_name": "চিতলমারী"
              },
              {
                "name": "Fakirhat ",
                "bn_name": "ফকিরহাট"
              },
              {
                "name": "Kachua ",
                "bn_name": "কচুয়া"
              },
              {
                "name": "Mollahat ",
                "bn_name": "মোল্লাহাট"
              },
              {
                "name": "Mongla ",
                "bn_name": "মোংলা"
              },
              {
                "name": "Morrelganj ",
                "bn_name": "মোড়েলগঞ্জ"
              },
              {
                "name": "Rampal ",
                "bn_name": "রামপাল"
              },
              {
                "name": "Sarankhola ",
                "bn_name": "শরণখোলা"
              }
            ]
          },
          {
            "name": "Chuadanga",
            "bn_name": "চুয়াডাঙ্গা",
            "lon": "88.841841",
            "lat": "23.6401961",
            "upazilas": [
              {
                "name": "Damurhuda ",
                "bn_name": "দামুড়হুদা"
              },
              {
                "name": "Chuadanga-S ",
                "bn_name": "চুয়াডাঙ্গা সদর"
              },
              {
                "name": "Jibannagar ",
                "bn_name": "জীবননগর"
              },
              {
                "name": "Alamdanga ",
                "bn_name": "আলমডাঙ্গা"
              }
            ]
          },
          {
            "name": "Jessore",
            "bn_name": "যশোর",
            "lon": "89.2081126",
            "lat": "23.16643",
            "upazilas": [
              {
                "name": "Abhaynagar ",
                "bn_name": "অভয়নগর "
              },
              {
                "name": "Keshabpur ",
                "bn_name": "কেশবপুর"
              },
              {
                "name": "Bagherpara ",
                "bn_name": "বাঘারপাড়া"
              },
              {
                "name": "Jessore Sadar ",
                "bn_name": "যশোর সদর"
              },
              {
                "name": "Chaugachha ",
                "bn_name": "চৌগাছা"
              },
              {
                "name": "Manirampur ",
                "bn_name": "মণিরামপুর "
              },
              {
                "name": "Jhikargachha ",
                "bn_name": "ঝিকরগাছা"
              },
              {
                "name": "Sharsha ",
                "bn_name": "শার্শা"
              }
            ]
          },
          {
            "name": "Jhenaidah",
            "bn_name": "ঝিনাইদহ",
            "lon": ",88.8519053",
            "lat": "23.5448176",
            "upazilas": [
              {
                "name": "Jhenaidah Sadar ",
                "bn_name": "ঝিনাইদহ সদর"
              },
              {
                "name": "Maheshpur ",
                "bn_name": "মহেশপুর"
              },
              {
                "name": "Kaliganj ",
                "bn_name": "কালীগঞ্জ"
              },
              {
                "name": "Kotchandpur ",
                "bn_name": "কোটচাঁদপুর "
              },
              {
                "name": "Shailkupa ",
                "bn_name": "শৈলকুপা"
              },
              {
                "name": "Harinakunda ",
                "bn_name": "হরিণাকুন্ডু"
              }
            ]
          },
          {
            "name": "Khulna",
            "bn_name": "খুলনা",
            "lon": "89.568679",
            "lat": "22.815774",
            "upazilas": [
              {
                "name": "Terokhada ",
                "bn_name": "তেরখাদা"
              },
              {
                "name": "Batiaghata ",
                "bn_name": "বটিয়াঘাটা "
              },
              {
                "name": "Dacope ",
                "bn_name": "দাকোপ"
              },
              {
                "name": "Dumuria ",
                "bn_name": "ডুমুরিয়া"
              },
              {
                "name": "Dighalia ",
                "bn_name": "দিঘলিয়া"
              },
              {
                "name": "Koyra ",
                "bn_name": "কয়রা"
              },
              {
                "name": "Paikgachha ",
                "bn_name": "পাইকগাছা"
              },
              {
                "name": "Phultala ",
                "bn_name": "ফুলতলা"
              },
              {
                "name": "Rupsa ",
                "bn_name": "রূপসা"
              },
              // {
              //   "name": "Aranghata",
              //   "bn_name": "à¦†à¦¡à¦¼à¦¾à¦‚à¦˜à¦¾à¦Ÿà¦¾"
              // },
              // {
              //   "name": "Daulatpur",
              //   "bn_name": "à¦¦à§Œà¦²à¦¤à¦ªà§à¦°"
              // },
              // {
              //   "name": "Harintana",
              //   "bn_name": "à¦¹à¦¾à¦°à¦¿à¦¨à§à¦¤à¦¾à¦¨à¦¾ "
              // },
              // {
              //   "name": "Horintana",
              //   "bn_name": "à¦¹à¦°à¦¿à¦£à¦¤à¦¾à¦¨à¦¾ "
              // },
              // {
              //   "name": "Khalishpur",
              //   "bn_name": "à¦–à¦¾à¦²à¦¿à¦¶à¦ªà§à¦°"
              // },
              // {
              //   "name": "Khanjahan Ali",
              //   "bn_name": "à¦–à¦¾à¦¨à¦œà¦¾à¦¹à¦¾à¦¨ à¦†à¦²à§€"
              // },
              {
                "name": "Khulna Sadar",
                "bn_name": "খুলনা "
              },
              // {
              //   "name": "Labanchora",
              //   "bn_name": "à¦²à¦¾à¦¬à¦¾à¦¨à¦›à§‹à¦°à¦¾"
              // },
              // {
              //   "name": "Sonadanga",
              //   "bn_name": "à¦¸à§‹à¦¨à¦¾à¦¡à¦¾à¦™à§à¦—à¦¾"
              // },
              // {
              //   "name": "Others",
              //   "bn_name": "à¦…à¦¨à§à¦¯à¦¾à¦¨à§à¦¯"
              // }
            ]
          },
          {
            "name": "Kushtia",
            "bn_name": "কুষ্টিয়া",
            "lon": "89.120482",
            "lat": "23.901258",
            "upazilas": [
              {
                "name": "Kushtia Sadar",
                "bn_name": "কুষ্টিয়া সদর"
              },
              {
                "name": "Kumarkhali",
                "bn_name": "কুমারখালী"
              },
              {
                "name": "Daulatpur",
                "bn_name": "দৌলতপুর"
              },
              {
                "name": "Mirpur",
                "bn_name": "মিরপুর"
              },
              {
                "name": "Bheramara",
                "bn_name": "ভেড়ামারা"
              },
              {
                "name": "Khoksa",
                "bn_name": "খোকসা"
              }
            ]
          },
          {
            "name": "Magura",
            "bn_name": "মাগুরা",
            "lon": "89.419956",
            "lat": "23.487337",
            "upazilas": [
              {
                "name": "Magura Sadar ",
                "bn_name": " মাগুরা সদর"
              },
              {
                "name": "Mohammadpur ",
                "bn_name": "মহম্মদপুর"
              },
              {
                "name": "Shalikha ",
                "bn_name": "শালিখা"
              },
              {
                "name": "Sreepur ",
                "bn_name": "শ্রীপুর"
              }
            ]
          },
          {
            "name": "Meherpur",
            "bn_name": "মেহেরপুর",
            "lon": "88.631821",
            "lat": "23.762213",
            "upazilas": [
              {
                "name": "Angni ",
                "bn_name": "গাংনী"
              },
              {
                "name": "Mujib Nagar ",
                "bn_name": "মুজিবনগর"
              },
              {
                "name": "Meherpur-S ",
                "bn_name": "মেহেরপুর সদর"
              }
            ]
          },
          {
            "name": "Narail",
            "bn_name": "নড়াইল",
            "lon": "89.512672",
            "lat": "23.172534",
            "upazilas": [
              {
                "name": "Narail-S Upazilla",
                "bn_name": "নড়াইল সদর"
              },
              {
                "name": "Lohagara Upazilla",
                "bn_name": "লোহাগড়া"
              },
              {
                "name": "Kalia Upazilla",
                "bn_name": "কালিয়া"
              }
            ]
          },
          {
            "name": "Satkhira",
            "bn_name": "সাতক্ষীরা",
            "lon": "88.9001453",
            "lat": "22.708427",
            "upazilas": [
              {
                "name": "Satkhira Sadar ",
                "bn_name": "সাতক্ষীরা সদর "
              },
              {
                "name": "Assasuni ",
                "bn_name": "আশাশুনি "
              },
              {
                "name": "Debhata ",
                "bn_name": "দেবহাটা"
              },
              {
                "name": "Tala ",
                "bn_name": "তালা"
              },
              {
                "name": "Kalaroa ",
                "bn_name": "কলারোয়া"
              },
              {
                "name": "Kaliganj ",
                "bn_name": "কালিগঞ্জ"
              },
              {
                "name": "Shyamnagar ",
                "bn_name": "শ্যামনগর"
              }
            ]
          }
        ]
      },
      {
        "name": "Rajshahi",
        "bn_name": "রাজশাহী",
        "districts": [
          {
            "name": "Bogra",
            "bn_name": "বগুড়া",
            "lon": "89.377755",
            "lat": "24.8465228",
            "upazilas": [
              {
                "name": "Adamdighi",
                "bn_name": "আদমদিঘি"
              },
              {
                "name": "Bogra Sadar",
                "bn_name": " বগুড়া সদর"
              },
              {
                "name": "Sherpur",
                "bn_name": "শেরপুর"
              },
              {
                "name": "Dhunat",
                "bn_name": "ধুনট"
              },
              {
                "name": "Dhupchanchia",
                "bn_name": "দুপচাচিঁয়া "
              },
              {
                "name": "Gabtali",
                "bn_name": "গাবতলী"
              },
              {
                "name": "Kahaloo",
                "bn_name": "কাহালু"
              },
              {
                "name": "Nandigram",
                "bn_name": "নন্দিগ্রাম"
              },
              {
                "name": "Sahajanpur",
                "bn_name": "শাজাহানপুর"
              },
              {
                "name": "Sariakandi",
                "bn_name": "সারিয়াকান্দি"
              },
              {
                "name": "Shibganj",
                "bn_name": "শিবগঞ্জ"
              },
              {
                "name": "Sonatala",
                "bn_name": "সোনাতলা"
              }
            ]
          },
          {
            "name": "Joypurhat",
            "bn_name": "জয়পুরহাট",
            "lon": "88.8193665",
            "lat": "25.0673481",
            "upazilas": [
              {
                "name": "Joypurhat S",
                "bn_name": "জয়পুরহাট সদর"
              },
              {
                "name": "Akkelpur",
                "bn_name": "আক্কেলপুর"
              },
              {
                "name": "Kalai",
                "bn_name": "কালাই"
              },
              {
                "name": "Khetlal",
                "bn_name": "ক্ষেতলাল"
              },
              {
                "name": "Panchbibi",
                "bn_name": "পাঁচবিবি"
              }
            ]
          },
          {
            "name": "Naogaon",
            "bn_name": "নওগাঁ",
            "lon": "88.9020532",
            "lat": "24.8059711",
            "upazilas": [
              {
                "name": "Naogaon Sadar ",
                "bn_name": "নওগাঁ সদর"
              },
              {
                "name": "Mohadevpur ",
                "bn_name": "	মহাদেবপুর"
              },
              {
                "name": "Manda ",
                "bn_name": "মান্দা"
              },
              {
                "name": "Niamatpur ",
                "bn_name": "নিয়ামতপুর"
              },
              {
                "name": "Atrai ",
                "bn_name": "আত্রাই"
              },
              {
                "name": "Raninagar ",
                "bn_name": "রাণীনগর"
              },
              {
                "name": "Patnitala ",
                "bn_name": "পত্নিতলা"
              },
              {
                "name": "Dhamoirhat ",
                "bn_name": "ধামইরহাট "
              },
              {
                "name": "Sapahar ",
                "bn_name": "সাপাহার"
              },
              {
                "name": "Porsha ",
                "bn_name": "পোরশা"
              },
              {
                "name": "Badalgachhi ",
                "bn_name": "বদলগাছী"
              }
            ]
          },
          {
            "name": "Natore",
            "bn_name": "নাটোর",
            "lon": "89.000282",
            "lat": "24.420556",
            "upazilas": [
              {
                "name": "Natore Sadar ",
                "bn_name": "নাটোর সদর"
              },
              {
                "name": "Baraigram ",
                "bn_name": "বড়াইগ্রাম"
              },
              {
                "name": "Bagatipara ",
                "bn_name": "বাগাতিপাড়া"
              },
              {
                "name": "Lalpur ",
                "bn_name": "লালপুর"
              },
              {
                "name": "Shingra",
                "bn_name": "সিংড়া"
              },
              {
                "name": "Gurodashpur ",
                "bn_name": "গুরুদাসপুর"
              },
              {
                "name": "Naldangha ",
                "bn_name": "নলডাঙ্গা"
              }
            ]
          },
          {
            "name": "Nawabganj",
            "bn_name": "চাঁপাইনবাবগঞ্জ",
            "lon": "88.2775122",
            "lat": "24.5965034",
            "upazilas": [
              {
                "name": "Bholahat ",
                "bn_name": "ভোলাহাট "
              },
              {
                "name": "Gomastapur ",
                "bn_name": "গোমস্তাপুর"
              },
              {
                "name": "Nachole ",
                "bn_name": "নাচোল"
              },
              {
                "name": "Nawabganj Sadar ",
                "bn_name": "চাঁপাইনবাবগঞ্জ সদর"
              },
              {
                "name": "Shibganj ",
                "bn_name": "শিবগঞ্জ"
              }
            ]
          },
          {
            "name": "Pabna",
            "bn_name": "পাবনা",
            "lon": "89.233645",
            "lat": "23.998524",
            "upazilas": [
              {
                "name": "Atgharia ",
                "bn_name": "আটঘরিয়া"
              },
              {
                "name": "Bera ",
                "bn_name": "বেড়া"
              },
              {
                "name": "Bhangura ",
                "bn_name": "ভাঙ্গুড়া"
              },
              {
                "name": "Chatmohar ",
                "bn_name": "চাটমোহর"
              },
              {
                "name": "Faridpur ",
                "bn_name": "ফরিদপুর"
              },
              {
                "name": "Ishwardi ",
                "bn_name": "ঈশ্বরদী"
              },
              {
                "name": "Pabna Sadar ",
                "bn_name": "পাবনা সদর "
              },
              {
                "name": "Santhia ",
                "bn_name": "সাঁথিয়া"
              },
              {
                "name": "Sujanagar ",
                "bn_name": "সুজানগর"
              }
            ]
          },
          {
            "name": "Rajshahi",
            "bn_name": "রাজশাহী",
            "lon": "88.5358044",
            "lat": "24.3801379",
            "upazilas": [
              {
                "name": "Bagha",
                "bn_name": "বাঘা"
              },
              {
                "name": "Bagmara",
                "bn_name": "বাগমারা"
              },
              {
                "name": "Charghat",
                "bn_name": "চারঘাট"
              },
              {
                "name": "Durgapur",
                "bn_name": "দুর্গাপুর"
              },
              {
                "name": "Godagari",
                "bn_name": "গোদাগাড়ী"
              },
              {
                "name": "Mohanpur",
                "bn_name": "মোহনপুর"
              },
              {
                "name": "Paba",
                "bn_name": "পবা "
              },
              {
                "name": "Puthia",
                "bn_name": "পুঠিয়া"
              },
              {
                "name": "Tanore",
                "bn_name": "তানোর"
              },
              // {
              //   "name": "Boalia",
              //   "bn_name": "à¦¬à§‹à¦¯à¦¼à¦¾à¦²à¦¿à¦¯à¦¼à¦¾"
              // },
              // {
              //   "name": "Motihar",
              //   "bn_name": "à¦®à¦¤à¦¿à¦¹à¦¾à¦°"
              // },
              // {
              //   "name": "Shahmokhdum",
              //   "bn_name": "à¦¶à¦¾à¦¹à§ à¦®à¦•à¦–à¦¦à§à¦® "
              // },
              // {
              //   "name": "Rajpara",
              //   "bn_name": "à¦°à¦¾à¦œà¦ªà¦¾à¦°à¦¾ "
              // },
              // {
              //   "name": "Others",
              //   "bn_name": "à¦…à¦¨à§à¦¯à¦¾à¦¨à§à¦¯"
              // }
            ]
          },
          {
            "name": "Sirajgonj",
            "bn_name": "সিরাজগঞ্জ",
            "lon": "89.7006815",
            "lat": "24.4533978",
            "upazilas": [
              {
                "name": "Sirajganj Sadar ",
                "bn_name": "সিরাজগঞ্জ সদর"
              },
              {
                "name": "Belkuchi ",
                "bn_name": "বেলকুচি"
              },
              {
                "name": "Chauhali ",
                "bn_name": "চৌহালি"
              },
              {
                "name": "Kamarkhanda ",
                "bn_name": "কামারখন্দ"
              },
              {
                "name": "Kazipur ",
                "bn_name": "কাজীপুর"
              },
              {
                "name": "Raiganj ",
                "bn_name": "রায়গঞ্জ"
              },
              {
                "name": "Shahjadpur ",
                "bn_name": "শাহজাদপুর"
              },
              {
                "name": "Tarash ",
                "bn_name": "তাড়াশ"
              },
              {
                "name": "Ullahpara ",
                "bn_name": "উল্লাপাড়া"
              }
            ]
          }
        ]
      },
      {
        "name": "Rangpur",
        "bn_name": "রংপুর",
        "districts": [
          {
            "name": "Dinajpur",
            "bn_name": "দিনাজপুর",
            "lon": "88.6354504",
            "lat": "25.6217061",
            "upazilas": [
              {
                "name": "Birampur ",
                "bn_name": "বিরামপুর"
              },
              {
                "name": "Birganj",
                "bn_name": "বীরগঞ্জ"
              },
              {
                "name": "Biral ",
                "bn_name": "বিরল"
              },
              {
                "name": "Bochaganj ",
                "bn_name": " বোচাগঞ্জ"
              },
              {
                "name": "Chirirbandar ",
                "bn_name": "চিরিরবন্দর"
              },
              {
                "name": "Phulbari ",
                "bn_name": "ফুলবাড়ী"
              },
              {
                "name": "Ghoraghat ",
                "bn_name": "ঘোড়াঘাট"
              },
              {
                "name": "Hakimpur ",
                "bn_name": "হাকিমপুর"
              },
              {
                "name": "Kaharole ",
                "bn_name": "কাহারোল"
              },
              {
                "name": "Khansama ",
                "bn_name": "খানসামা"
              },
              {
                "name": "Dinajpur Sadar ",
                "bn_name": "দিনাজপুর সদর"
              },
              {
                "name": "Nawabganj",
                "bn_name": "নবাবগঞ্জ"
              },
              {
                "name": "Parbatipur ",
                "bn_name": "পার্বতীপুর"
              }
            ]
          },
          {
            "name": "Gaibandha",
            "bn_name": "গাইবান্ধা",
            "lon": "89.528088",
            "lat": "25.328751",
            "upazilas": [
              {
                "name": "Fulchhari",
                "bn_name": "ফুলছড়ি"
              },
              {
                "name": "Gaibandha sadar",
                "bn_name": "গাইবান্ধা সদর"
              },
              {
                "name": "Gobindaganj",
                "bn_name": "গোবিন্দগঞ্জ"
              },
              {
                "name": "Palashbari",
                "bn_name": "পলাশবাড়ী"
              },
              {
                "name": "Sadullapur",
                "bn_name": "সাদুল্লাপুর "
              },
              {
                "name": "Saghata",
                "bn_name": "সাঘাটা"
              },
              {
                "name": "Sundarganj",
                "bn_name": "সুন্দরগঞ্জ"
              }
            ]
          },
          {
            "name": "Kurigram",
            "bn_name": "কুড়িগ্রাম",
            "lon": "89.636174",
            "lat": "25.805445",
            "upazilas": [
              {
                "name": "Kurigram Sadar",
                "bn_name": "কুড়িগ্রাম সদর"
              },
              {
                "name": "Nageshwari",
                "bn_name": "নাগেশ্বরী"
              },
              {
                "name": "Bhurungamari",
                "bn_name": "ভুরুঙ্গামারী"
              },
              {
                "name": "Phulbari",
                "bn_name": "ফুলবাড়ী"
              },
              {
                "name": "Rajarhat",
                "bn_name": "রাজারহাট"
              },
              {
                "name": "Ulipur",
                "bn_name": "উলিপুর"
              },
              {
                "name": "Chilmari",
                "bn_name": "চিলমারী"
              },
              {
                "name": "Rowmari",
                "bn_name": "রৌমারী"
              },
              {
                "name": "Char Rajibpur",
                "bn_name": "চর রাজিবপুর"
              }
            ]
          },
          {
            "name": "Lalmonirhat",
            "bn_name": "লালমনিরহাট",
            "lon": "88.6819107",
            "lat": "26.1254497",
            "upazilas": [
              {
                "name": "Lalmanirhat Sadar",
                "bn_name": "লালমনিরহাট সদর"
              },
              {
                "name": "Aditmari",
                "bn_name": "আদিতমারী"
              },
              {
                "name": "Kaliganj",
                "bn_name": "কালীগঞ্জ"
              },
              {
                "name": "Hatibandha",
                "bn_name": "হাতীবান্ধা"
              },
              {
                "name": "Patgram",
                "bn_name": "পাটগ্রাম"
              }
            ]
          },
          {
            "name": "Nilphamari",
            "bn_name": "নীলফামারী",
            "lon": "88.856006",
            "lat": "25.931794",
            "upazilas": [
              {
                "name": "Nilphamari Sadar",
                "bn_name": "নীলফামারী সদর"
              },
              {
                "name": "Saidpur",
                "bn_name": "সৈয়দপুর"
              },
              {
                "name": "Jaldhaka",
                "bn_name": "জলঢাকা"
              },
              {
                "name": "Kishoreganj",
                "bn_name": "কিশোরগঞ্জ"
              },
              {
                "name": "Domar",
                "bn_name": "ডোমার"
              },
              {
                "name": "Dimla",
                "bn_name": "ডিমলা"
              }
            ]
          },
          {
            "name": "Panchagarh",
            "bn_name": "পঞ্চগড়",
            "lon": "88.5387754",
            "lat": "26.3308627",
            "upazilas": [
              {
                "name": "Panchagarh Sadar",
                "bn_name": "	পঞ্চগড় সদর"
              },
              {
                "name": "Debiganj",
                "bn_name": "দেবীগঞ্জ"
              },
              {
                "name": "Boda",
                "bn_name": "বোদা"
              },
              {
                "name": "Atwari",
                "bn_name": "আটোয়ারী"
              },
              {
                "name": "Tetulia",
                "bn_name": "তেতুলিয়া"
              }
            ]
          },
          {
            "name": "Rangpur",
            "bn_name": "রংপুর",
            "lon": "89.244462",
            "lat": "25.7558096",
            "upazilas": [
              {
                "name": "Badarganj",
                "bn_name": "বদরগঞ্জ"
              },
              {
                "name": "Mithapukur",
                "bn_name": "মিঠাপুকুর"
              },
              {
                "name": "Gangachara",
                "bn_name": "গংগাচড়া"
              },
              {
                "name": "Kaunia",
                "bn_name": "কাউনিয়া"
              },
              {
                "name": "Rangpur Sadar",
                "bn_name": "রংপুর সদর"
              },
              {
                "name": "Pirgachha",
                "bn_name": "পীরগাছা"
              },
              {
                "name": "Pirganj",
                "bn_name": "পীরগঞ্জ"
              },
              {
                "name": "Taraganj",
                "bn_name": "তারাগঞ্জ"
              }
            ]
          },
          {
            "name": "Thakurgaon",
            "bn_name": "ঠাকুরগাঁও",
            "lon": "88.4616834",
            "lat": "26.0336945",
            "upazilas": [
              {
                "name": "Thakurgaon Sadar ",
                "bn_name": "ঠাকুরগাঁও সদর"
              },
              {
                "name": "Pirganj ",
                "bn_name": "পীরগঞ্জ"
              },
              {
                "name": "Baliadangi ",
                "bn_name": "বালিয়াডাঙ্গী"
              },
              {
                "name": "Haripur ",
                "bn_name": "হরিপুর"
              },
              {
                "name": "Ranisankail ",
                "bn_name": "রাণীশংকৈল"
              }
            ]
          }
        ]
      },
      {
        "name": "Sylhet",
        "bn_name": "সিলেট",
        "districts": [
          {
            "name": "Habiganj",
            "bn_name": "হবিগঞ্জ",
            "lon": "91.41553",
            "lat": "24.374945",
            "upazilas": [
              {
                "name": "Ajmiriganj",
                "bn_name": "আজমিরীগঞ্জ"
              },
              {
                "name": "Baniachang",
                "bn_name": "বানিয়াচং"
              },
              {
                "name": "Bahubal",
                "bn_name": "বাহুবল"
              },
              {
                "name": "Chunarughat",
                "bn_name": "চুনারুঘাট"
              },
              {
                "name": "Habiganj Sadar",
                "bn_name": "হবিগঞ্জ সদর"
              },
              {
                "name": "Lakhai",
                "bn_name": "লাখাই"
              },
              {
                "name": "Madhabpur",
                "bn_name": "মাধবপুর"
              },
              {
                "name": "Nabiganj",
                "bn_name": "নবীগঞ্জ"
              },
              {
                "name": "Shaistagonj ",
                "bn_name": "শায়েস্তাগঞ্জ"
              }
            ]
          },
          {
            "name": "Maulvibazar",
            "bn_name": "মৌলভীবাজার",
            "lon": "91.777417",
            "lat": "24.482934",
            "upazilas": [
              {
                "name": "Moulvibazar Sadar",
                "bn_name": "মৌলভীবাজার সদর"
              },
              {
                "name": "Barlekha",
                "bn_name": "বড়লেখা"
              },
              {
                "name": "Juri",
                "bn_name": "জুড়ী"
              },
              {
                "name": "Kamalganj",
                "bn_name": "কমলগঞ্জ"
              },
              {
                "name": "Kulaura",
                "bn_name": "কুলাউড়া"
              },
              {
                "name": "Rajnagar",
                "bn_name": "রাজনগর"
              },
              {
                "name": "Sreemangal",
                "bn_name": "শ্রীমঙ্গল"
              }
            ]
          },
          {
            "name": "Sunamganj",
            "bn_name": "সুনামগঞ্জ",
            "lon": "91.3950115",
            "lat": "25.0658042",
            "upazilas": [
              {
                "name": "Bishwamvarpur",
                "bn_name": "বিশ্বম্ভরপুর"
              },
              {
                "name": "Chhatak",
                "bn_name": "ছাতক"
              },
              {
                "name": "Derai",
                "bn_name": "দিরাই"
              },
              {
                "name": "Dharampasha",
                "bn_name": "ধর্মপাশা"
              },
              {
                "name": "Dowarabazar",
                "bn_name": "দোয়ারাবাজার"
              },
              {
                "name": "Jagannathpur",
                "bn_name": "জগন্নাথপুর"
              },
              {
                "name": "Jamalganj",
                "bn_name": "জামালগঞ্জ,"
              },
              {
                "name": "Sulla",
                "bn_name": "শাল্লা"
              },
              {
                "name": "Sunamganj Sadar",
                "bn_name": "সুনামগঞ্জ সদর"
              },
              {
                "name": "South Sunamganj",
                "bn_name": "দক্ষিণ সুনামগঞ্জ"
              },
              {
                "name": "Tahirpur",
                "bn_name": "তাহিরপুর"
              }
            ]
          },
          {
            "name": "Sylhet",
            "bn_name": "সিলেট",
            "lon": "91.8697894",
            "lat": "24.8897956",
            "upazilas": [
              {
                "name": "Sylhet Sadar",
                "bn_name": "সিলেট সদর"
              },
              {
                "name": "Beanibazar",
                "bn_name": "বিয়ানীবাজার"
              },
              {
                "name": "Bishwanath",
                "bn_name": "বিশ্বনাথ"
              },
              {
                "name": "Dakshin Surma ",
                "bn_name": " দক্ষিণ সুরমা"
              },
              {
                "name": "Balaganj",
                "bn_name": "বালাগঞ্জ"
              },
              {
                "name": "Companiganj",
                "bn_name": "কোম্পানীগঞ্জ"
              },
              {
                "name": "Fenchuganj",
                "bn_name": "ফেঞ্চুগঞ্জ"
              },
              {
                "name": "Golapganj",
                "bn_name": "গোলাপগঞ্জ"
              },
              {
                "name": "Gowainghat",
                "bn_name": "গোয়াইনঘাট"
              },
              {
                "name": "Jaintiapur",
                "bn_name": "জৈন্তাপুর"
              },
              {
                "name": "Kanaighat",
                "bn_name": "কানাইঘাট"
              },
              {
                "name": "Zakiganj",
                "bn_name": "জকিগঞ্জ"
              },
              // {
              //   "name": "Nobigonj",
              //   "bn_name": "à¦¨à¦¬à§€à¦—à¦žà§à¦œ"
              // },
              // {
              //   "name": "Airport",
              //   "bn_name": "à¦¬à¦¿à¦®à¦¾à¦¨à¦¬à¦¨à§à¦¦à¦°"
              // },
              // {
              //   "name": "Hazrat Shah Paran",
              //   "bn_name": "à¦¹à¦¯à¦°à¦¤ à¦¶à¦¾à¦¹ à¦ªà¦°à¦¾à¦£"
              // },
              // {
              //   "name": "Jalalabad",
              //   "bn_name": "à¦œà¦¾à¦²à¦¾à¦²à¦¾à¦¬à¦¾à¦¦"
              // },
              // {
              //   "name": "Kowtali",
              //   "bn_name": "à¦•à§‹à¦¤à§‹à¦¯à¦¼à¦¾à¦²à§€"
              // },
              // {
              //   "name": "Moglabazar",
              //   "bn_name": "à¦®à§‹à¦—à¦²à¦¾à¦¬à¦¾à¦œà¦¾à¦°"
              // },
              {
                "name": "Osmani Nagar",
                "bn_name": "ওসমানী"
              },
              // {
              //   "name": "South Surma",
              //   "bn_name": "à¦¦à¦•à§à¦·à¦¿à¦£ à¦¸à§à¦°à¦®à¦¾"
              // },
              // {
              //   "name": "Others",
              //   "bn_name": "à¦…à¦¨à§à¦¯à¦¾à¦¨à§à¦¯"
              // }
            ]
          }
        ]
      },
      {
        "name": "Mymensingh",
        "bn_name": "ময়মনসিংহ",
        "districts": [
          {
            "name": "Jamalpur",
            "bn_name": "জামালপুর",
            "lon": "89.937775",
            "lat": "24.937533",
            "upazilas": [
              {
                "name": "Dewanganj ",
                "bn_name": "দেওয়ানগঞ্জ"
              },
              {
                "name": "Baksiganj ",
                "bn_name": "বকশীগঞ্জ"
              },
              {
                "name": "Islampur ",
                "bn_name": "ইসলামপুর"
              },
              {
                "name": "Jamalpur Sadar ",
                "bn_name": "জামালপুর সদর"
              },
              {
                "name": "Madarganj ",
                "bn_name": "মাদারগঞ্জ"
              },
              {
                "name": "Melandaha ",
                "bn_name": "মেলান্দহ"
              },
              {
                "name": "Sarishabari ",
                "bn_name": "সরিষাবাড়ী "
              },
              // {
              //   "name": "Narundi Police I.C",
              //   "bn_name": "à¦¨à¦¾à¦°à§à¦¨à§à¦¦à¦¿"
              // },
              // {
              //   "name": "Others",
              //   "bn_name": "à¦…à¦¨à§à¦¯à¦¾à¦¨à§à¦¯"
              // }
            ]
          },
          {
            "name": "Mymensingh",
            "bn_name": "ময়মনসিংহ",
            "lon": "89.8875985",
            "lat": "24.7236688",
            "upazilas": [
              {
                "name": "Bhaluka",
                "bn_name": "ভালুকা"
              },
              {
                "name": "Trishal",
                "bn_name": "ত্রিশাল"
              },
              {
                "name": "Haluaghat",
                "bn_name": "হালুয়াঘাট"
              },
              {
                "name": "Muktagachha",
                "bn_name": "মুক্তাগাছা"
              },
              {
                "name": "Dhobaura",
                "bn_name": "ধোবাউড়া"
              },
              {
                "name": "Fulbaria",
                "bn_name": "ফুলবাড়ীয়া"
              },
              {
                "name": "Gaffargaon",
                "bn_name": "গফরগাঁও"
              },
              {
                "name": "Gauripur",
                "bn_name": "গৌরীপুর"
              },
              {
                "name": "Ishwarganj",
                "bn_name": "ঈশ্বরগঞ্জ"
              },
              {
                "name": "Mymensingh Sadar",
                "bn_name": "ময়মনসিংহ সদর"
              },
              {
                "name": "Nandail",
                "bn_name": "নান্দাইল"
              },
              {
                "name": "Phulpur",
                "bn_name": "ফুলপুর"
              },
              {
                "name":"Tarakanda",
                "bn_name":"তারাকান্দা"
              }
            ]
          },
          {
            "name": "Netrokona",
            "bn_name": "নেত্রকোণা",
            "lon": "90.727887",
            "lat": "24.870955",
            "upazilas": [
              {
                "name": "Kendua Upazilla",
                "bn_name": "কেন্দুয়া"
              },
              {
                "name": "Atpara Upazilla",
                "bn_name": "আটপাড়া"
              },
              {
                "name": "Barhatta Upazilla",
                "bn_name": "বারহাট্টা"
              },
              {
                "name": "Durgapur Upazilla",
                "bn_name": "দুর্গাপুর"
              },
              {
                "name": "Kalmakanda Upazilla",
                "bn_name": "কলমাকান্দা"
              },
              {
                "name": "Madan Upazilla",
                "bn_name": "মদন"
              },
              {
                "name": "Mohanganj Upazilla",
                "bn_name": "মোহনগঞ্জ"
              },
              {
                "name": "Netrakona-S Upazilla",
                "bn_name": "নেত্রকোণা সদর"
              },
              {
                "name": "Purbadhala Upazilla",
                "bn_name": "পূর্বধলা"
              },
              {
                "name": "Khaliajuri Upazilla",
                "bn_name": "খালিয়াজুরী"
              }
            ]
          },
          {
            "name": "Sherpur",
            "bn_name": "শেরপুর",
            "lon": "90.0152966",
            "lat": "25.0204933",
            "upazilas": [
              {
                "name": "Jhenaigati ",
                "bn_name": "ঝিনাইগাতী"
              },
              {
                "name": "Nakla ",
                "bn_name": "নকলা"
              },
              {
                "name": "Nalitabari ",
                "bn_name": "নালিতাবাড়ী"
              },
              {
                "name": "Sherpur Sadar ",
                "bn_name": "শেরপুর সদর"
              },
              {
                "name": "Sreebardi ",
                "bn_name": "শ্রীবরদী"
              }
            ]
          }
        ]
      }
    ]
  
  